import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PrescriberDetails from "./PrescriberDetails";
import PharmacyDetails from "./PharmacyDetails";
import MemberDetails from "./MemberDetails";
import Badge from "react-bootstrap/Badge";
import OverlayBadge from "components/OverlayBadge";
import { MemberConstants } from "lookup/MemberConstants";

const EntityDetails = (props) => {
  const { prescriber, pharmacy, patient, globalSwitchId, wfItemId } = props;
  const isDisabled = props.isDisabled == null ? false : props.isDisabled;
  const isInAW = props.isInAW == null ? false : props.isInAW;

  return (
    <div>
      {!isInAW ? (
        <Tabs defaultActiveKey="prescriber">
          <Tab
            eventKey="prescriber"
            title="Prescriber"
            style={{ padding: 10, paddingLeft: 15 }}
          >
            <PrescriberDetails
              globalSwitchId={globalSwitchId}
              prescriber={prescriber}
              isDisabled={isDisabled}
              updateData={props.refreshSwim}
              wfItemId={wfItemId}
            />
          </Tab>
          <Tab
            eventKey="pharmacy"
            title="Pharmacy"
            style={{ padding: 10, paddingLeft: 15 }}
          >
            <PharmacyDetails
              globalSwitchId={globalSwitchId}
              pharmacy={pharmacy}
              isDisabled={isDisabled}
              updateData={props.refreshSwim}
              wfItemId={wfItemId}
            />
          </Tab>
          <Tab
            eventKey="patient"
            title="Member"
            style={{ padding: 10, paddingLeft: 15 }}
          >
            <MemberDetails
              patient={patient}
              globalSwitchId={globalSwitchId}
              isDisabled={isDisabled}
              updateData={props.refreshSwim}
            />
          </Tab>
        </Tabs>
      ) : (
        <>
          <div className="boxed-div-blue">
            <div className="entity-heading">Prescriber <OverlayBadge placement="top" bg="info" badgeText={prescriber.state} hoverText={prescriber.rescheduleInterval} />
            </div>
            <PrescriberDetails
              globalSwitchId={globalSwitchId}
              prescriber={prescriber}
              isDisabled={isDisabled}
              updateData={props.updateData}
              wfItemId={wfItemId}
              isFromAW={isInAW}
            />
          </div>
          <div className="boxed-div-blue">
            <div className="entity-heading">Pharmacy <OverlayBadge placement="top" bg="info" badgeText={pharmacy.state} hoverText={pharmacy.rescheduleInterval} />
            </div>
            <PharmacyDetails
              globalSwitchId={globalSwitchId}
              pharmacy={pharmacy}
              isDisabled={isDisabled}
              updateData={props.updateData}
              wfItemId={wfItemId}
              isFromAW={isInAW}
            />
          </div >
          <div className="boxed-div-blue">
            <div className="entity-heading">Member <Badge bg="info">{MemberConstants.MEMBER_RELATIONSHIP_MAPPING[patient.relationship] ? MemberConstants.MEMBER_RELATIONSHIP_MAPPING[patient.relationship] : "Unknown"}</Badge></div>
            <MemberDetails
              patient={patient}
              globalSwitchId={globalSwitchId}
              isDisabled={isDisabled}
              updateData={props.updateData}
              wfItemId={wfItemId}
              isFromAW={isInAW}
            />
          </div>
        </>
      )}
    </div >
  );
};

export default EntityDetails;
