import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import Spinner from "components/Spinner";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { AgentWorkspaceConstants } from "lookup/AgentWorkspaceConstants";
import { activityEventEmitter } from "./ActivityEventEmitter";
import {
  isStringEmptyOrNull,
  setTimeTo8am,
  isCollectionNullOrEmpty,
} from "services/UtilityService";
import { Alert, FormGroup, FormLabel, FormSelect } from "react-bootstrap";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import DateTimePicker from "react-datetime-picker";
import "assets/css/agentworkspace.css";
import { showNotification } from "services/NotificationService";
import { useState, useEffect } from "react";
import ButtonWithPopover from "components/ButtonWithPopover";
import UpdateActivityState from "./UpdateActivityState";
import { isLoggedInUserAWAdmin } from "services/AWUtilityService";
import { BsInfoCircle } from "react-icons/bs";
import OpenModalButtonIcon from "components/OpenModalButtonIcon";
import { HOLD_TIME_LIMIT } from "lookup/AgentWorkspaceConstants";

const customStyles = {
  multiValue: (base) => ({
    background: "#007BBB",
    display: "inline-flex",
    color: "white",
    borderRadius: "11px",
    alignItems: "center",
  }),
  multiValueLabel: (base) => ({
    fontSize: "13px",
    color: "white",
    padding: "2px 4px 2px 4px",
  }),
};

const CloseActivty = (props) => {
  const prescriberId = props.prescriberId;
  const activityId = props.activityId;
  const callCounter = props.callCounter;
  const activityStatus = props.activityStatus;
  const callGroupId = props.callGroupId;
  const version = props.activityVersion;
  const data = props.data;
  const isMemberActivityCreatable = props.isMemberActivityCreatable;
  const [activitiesList, setActivitiesList] = useState([activityId]);
  const [rescheduleDate, setRescheduleDate] = useState(new Date());
  const [activityState, setActivityState] = useState(props.activityState);
  const [validSwitchListForResend, setValidSwitchListForResend] = useState([]);
  const [validSwitchListForResendLoading, setValidSwitchListForResendLoading] =
    useState(false);

  const [switchDetails, setSwitchDetails] = useState([]);
  const [isStateDisabled, setIsStateDisabled] = useState(
    props.activityState === "Member Call"
  );

  const [switchOptions, setSwitchOptions] = useState([]);
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [futureSwitchHandlers, setFutureSwitchHandlers] = useState([]);
  const [
    rejectionReasonsToFutureSwitchHandlersMapping,
    setRejectionReasonsToFutureSwitchHandlersMapping,
  ] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [entitySwitchesLoading, setEntitySwitchesLoading] = useState(false);
  const [latestAgentNote, setLatestAgentNote] = useState("");
  const [latestAgentNoteId, setLatestAgentNoteId] = useState(null);
  const [isCloseReasonSelected, setCloseReasonSelected] = useState(false);
  const [isSwitchDispositionSelected, setSwitchDispositionSelected] =
    useState(false);
  const [verifiedPhone, setVerifiedPhone] = useState(false);
  const [verifiedFax, setVerifiedFax] = useState(false);
  const [cashPay, setCashPay] = useState(false);
  const [callTemp, setCallTemp] = useState(null);
  const [activityData, setActivityData] = useState([]);
  const validateForm = (
    dateTime,
    closeReason,
    holdFor30Days,
    doNotReschedule
  ) => {
    //Validate if the date for scheduling cycle should be greater than current date
    if (!holdFor30Days && !doNotReschedule) {
      const currentDate = new Date();
      if (
        AgentWorkspaceConstants.closeReasonsForRescheduling.includes(
          closeReason
        )
      ) {
        if (!dateTime)
          return "You need to enter a reschedule date for this closing reason.";
        if (dateTime < currentDate)
          return "Date and Time should be greater than current Time";
      }
    }
    return null;
  };

  const onDateChange = (rescheduleDate) => {
    setRescheduleDate(
      rescheduleDate == null ? setTimeTo8am(rescheduleDate) : rescheduleDate
    );
  };

  useEffect(() => {
    if (prescriberId) {
      sendAuthenticatedAsyncRequest(
        `/agent-workspace/switches-with-open-call-activities?prescriberInfoUuids=${encodeURIComponent(
          prescriberId
        )}`,
        "GET",
        null,
        (r) => {
          setActivityData(r.data);
        },
        (e) => {
          showNotification({
            title: `Unable to Fetch Relevant Activities!`,
            message:
              getErrorMessage(e.data) ||
              getErrorMessage("SOMETHING_WENT_WRONG"),
            position: "tr",
            type: "error",
          });
        }
      );
    }
  }, []);

  useEffect(() => {
    sendAuthenticatedAsyncRequest(
      `/agent-workspace/${callGroupId}/${activityId}/get-close-activity-details`,
      "GET",
      null,
      (r) => {
        const switchInfo = r.data.switchDetails;
        const switchList = switchInfo.map((sw) => {
          return {
            value: sw.activityUuid,
            label: sw.switchInfoId,
          };
        });
        setSwitchDetails(switchList);
        setSwitchOptions([
          { value: "*", label: "All" },
          ...switchList.filter((sw) => sw.value != activityId),
        ]);
        setRejectionReasons(r.data.rejectionReasons);
        setFutureSwitchHandlers(r.data.futureSwitchHandlers);
        setRejectionReasonsToFutureSwitchHandlersMapping(
          r.data.rejectionReasonsToFutureSwitchHandlersMapping
        );
        setAgentsList(r.data.agentsList);
        setLatestAgentNote(r.data.latestAgentNote.note);
        setLatestAgentNoteId(r.data.latestAgentNote.noteId);
        setVerifiedPhone(r.data.verifiedPhone);
        setVerifiedFax(r.data.verifiedFax);
        setCashPay(r.data.cashPay);
        setLoading(false);
      },
      (r) => {
        setLoading(false);
        showNotification({
          title: "Error fetching open activities",
          message: "Something went Wrong.",
          position: "tr",
          type: "error",
        });
      }
    );
  }, []);

  const getCloseActivityDetails = () => {
    sendAuthenticatedAsyncRequest(
      `/agent-workspace/${callGroupId}/${activityId}/get-close-activity-details`,
      "GET",
      null,
      (r) => {
        const switchInfo = r.data.switchDetails;
        const switchList = switchInfo.map((sw) => {
          return {
            value: sw.activityUuid,
            label: sw.switchInfoId,
          };
        });
        setSwitchDetails(switchList);
        let allSwitchesActivities = [];
        switchList.forEach((sw) => allSwitchesActivities.push(sw.value));
        setActivitiesList([activityId]);
        setSwitchOptions([
          { value: "*", label: "All" },
          ...switchList.filter((sw) => sw.value != activityId),
        ]);
        setRejectionReasons(r.data.rejectionReasons);
        setFutureSwitchHandlers(r.data.futureSwitchHandlers);
        setRejectionReasonsToFutureSwitchHandlersMapping(
          r.data.rejectionReasonsToFutureSwitchHandlersMapping
        );
        setAgentsList(r.data.agentsList);
        setLatestAgentNote(r.data.latestAgentNote.note);
        setLatestAgentNoteId(r.data.latestAgentNote.noteId);
        setVerifiedPhone(r.data.verifiedPhone);
        setVerifiedFax(r.data.verifiedFax);
        setCashPay(r.data.cashPay);
        setLoading(false);
      },
      (r) => {
        setLoading(false);
        showNotification({
          title: "Error fetching open activities",
          message: "Something went Wrong.",
          position: "tr",
          type: "error",
        });
      }
    );
  };

  const submitForm = (originalValues, setSubmitting, closeModal) => {
    // it's best to create a clone because params are passed through reference

    const {
      closeReason,
      note,
      holdFor30Days,
      applyToMultipleSwitches,
      doNotReschedule,
      rejectionReason,
      futureSwitchTreatment,
      assignAgent,
      assignedAgent,
      taskReason,
      switchDisposition,
      applyToAllEntities,
    } = originalValues;
    const event = holdFor30Days ? "HOLD" : "CLOSE";
    let error = validateForm(
      rescheduleDate,
      closeReason,
      holdFor30Days,
      doNotReschedule
    );
    if (error === null) {
      showNotification({
        title: `${event} event in progress`,
        message: `This might take sometime! The action is being performed in the background.`,
        position: "tr",
        type: "warning",
        duration: 15000,
      });
      activityEventEmitter(
        {
          closeReason,
          switchDisposition,
          state: activityState,
          applyToMultipleSwitches,
          doNotReschedule,
          verifiedPhone,
          verifiedFax,
          cashPay,
          applyToAllEntities,
          activitiesList,
          note,
          latestAgentNoteId,
          holdFor30Days,
          rescheduleDate,
          rejectionReason,
          futureSwitchTreatment,
          taskReason,
          assignAgent,
          assignedAgent,
          version,
          data,
          switchOptions,
          event,
          callTemp,
        },
        activityId,
        {
          setSubmitting,
          closeModal,
          resetActivityUponClose:
            event === "CLOSE" ? props.resetActivityUponClose : null,
          refreshActivityList: props.refreshActivityList,
          refreshActivity:
            AgentWorkspaceConstants.closeReasonsThatClosesActivity.includes(
              closeReason
            ) ||
            AgentWorkspaceConstants.switchDispositionsThatClosesActivity.includes(
              switchDisposition
            )
              ? props.refreshActivity
              : null,
          goBackIfCallGroupClosed: props.goBackIfCallGroupClosed,
          goBackIfNoActionTakenOrGroupClose:
            props.goBackIfNoActionTakenOrGroupClose,
        }
      );
    } else {
      setSubmitting(false);
      showNotification({
        title: `Unable to execute ${event} event on Activity!`,
        message: error,
        position: "tr",
        type: "error",
      });
    }
  };

  const showCashPayCheckbox = (closeReason) => {
    return props.activityType == "CALL" && closeReason != HOLD_TIME_LIMIT;
  };

  const showRescheduleDateField = (
    closeReason,
    holdFor30Days,
    doNotReschedule
  ) => {
    return (
      AgentWorkspaceConstants.closeReasonsForReschedulingFieldDisplay.includes(
        closeReason
      ) &&
      !holdFor30Days &&
      !doNotReschedule
    );
  };

  const showConfirmDispositionDialog = (
    closeReason,
    switchDisposition,
    holdFor30Days,
    verifiedPhone,
    verifiedFax
  ) => {
    return (
      (AgentWorkspaceConstants.closeReasonsForCloseConfirmation.includes(
        closeReason
      ) ||
        AgentWorkspaceConstants.switchDispositionsForCloseConfirmation.includes(
          switchDisposition
        ) || 
        ( AgentWorkspaceConstants.closeReasonsForClosingCallActivity.includes(
        closeReason) && props.entityType === 'PRESCRIBER')
        ||
        verifiedPhone ||
        verifiedFax) &&
      !holdFor30Days
    );
  };

  const showGroupSaveOption = (
    closeReason,
    switchDisposition,
    holdFor30Days,
    cashPay
  ) => {
    return (
      switchDetails.length > 1 &&
      (AgentWorkspaceConstants.closeReasonsForGroupSaveOption.includes(
        closeReason
      ) ||
        AgentWorkspaceConstants.switchDispositionsForGroupSaveOption.includes(
          switchDisposition
        ) ||
        holdFor30Days) &&
      !cashPay
    );
  };

  const showAssignAgentOption = (closeReason, switchDisposition) => {
    return (
      !isStringEmptyOrNull(closeReason) &&
      !AgentWorkspaceConstants.closeReasonsToExcludeForAssignAgent.includes(
        closeReason
      ) &&
      !AgentWorkspaceConstants.switchDispositionsToExcludeForAssignAgent.includes(
        switchDisposition
      )
    );
  };

  const setShowPrescriberOptions = () => {
    const activityName = props.activityName;
    return activityName && activityName === "Call PRESCRIBER";
  };

  const showUpdateActivityOption = () => {
    const activityName = props.activityName;
    return (
      activityName &&
      (activityName === "Call PRESCRIBER" || activityName === "Call PHARMACY")
    );
  };

  const onMultiSelectChange = (closeReason, setFieldValue, options) => {
    let tempSwitchList = [];
    if (options) {
      options.map((x) => tempSwitchList.push(x.value));
    }

    if (tempSwitchList && tempSwitchList.includes("*")) {
      // in this case, group select will contain all activity ids
      // and options will be empty
      let allSwitches = [];
      switchDetails.forEach((sw) => allSwitches.push(sw.value));
      setActivitiesList(allSwitches);
      setFieldValue(
        "switchList",
        switchDetails.filter((sw) => sw.value != activityId)
      );
      setSwitchOptions([]);
      if (closeReason === "Fax Resend") {
        getResendableSwitches(allSwitches);
      }
    } else if (
      tempSwitchList &&
      tempSwitchList.length > 0 &&
      !tempSwitchList.includes("*")
    ) {
      // in this case, group select will be equal to templist
      // and options will contains all options except *
      setActivitiesList([activityId, ...tempSwitchList]);
      setFieldValue(
        "switchList",
        switchDetails.filter((sw) => tempSwitchList.includes(sw.value))
      );
      if (switchOptions.length === 0) {
        setSwitchOptions(switchDetails.filter((sw) => sw.value != activityId));
      } else {
        setSwitchOptions(switchOptions.filter((x) => x.value != "*"));
      }

      if (closeReason === "Fax Resend") {
        getResendableSwitches([activityId, ...tempSwitchList]);
      }
    } else {
      // in this case, group select will be equal to tempList
      // and options will be refreshed
      setActivitiesList([activityId]);
      setFieldValue("switchList", []);
      setSwitchOptions([
        { value: "*", label: "All" },
        ...switchDetails.filter((sw) => sw.value != activityId),
      ]);
      if (closeReason === "Fax Resend") {
        getResendableSwitches([activityId]);
      }
    }
  };

  const handleStateUpdate = (state) => {
    setActivityState(state.value);
  };

  const handleGroupSaveCheckbox = (closeReason, setFieldValue, value) => {
    setFieldValue("applyToMultipleSwitches", !value);
    if (!value === false) {
      setActivitiesList([activityId]);
      setFieldValue("switchList", []);
      setSwitchOptions([
        { value: "*", label: "All" },
        ...switchDetails.filter((sw) => sw.value != activityId),
      ]);
      if (closeReason === "Fax Resend") {
        getResendableSwitches([activityId]);
      }
    }
  };

  const handleAssignAgentCheckbox = (setFieldValue, value) => {
    setFieldValue("assignAgent", !value);
  };

  const resetFormForCloseReasonUpdate = (setFieldValue) => {
    setFieldValue("switchList", []);
    setActivitiesList([activityId]);
    setSwitchOptions([
      { value: "*", label: "All" },
      ...switchDetails.filter((sw) => sw.value != activityId),
    ]);
    setFieldValue("applyToMultipleSwitches", false);
    setFieldValue("doNotReschedule", false);
    setFieldValue("applyToAllEntities", false);
    setFieldValue("rejectionReason", "");
    setFieldValue("futureSwitchTreatment", "");
    setFieldValue("taskReason", "");
    setFieldValue("switchDisposition", "");
  };

  const setInitialDateAndCloseReasonAndState = (setFieldValue, e) => {
    const closeReason = e.target.value;
    setFieldValue("closeReason", closeReason);
    setCloseReasonSelected(!isStringEmptyOrNull(closeReason) ? true : false);
    resetFormForCloseReasonUpdate(setFieldValue);

    let prePopulatedDate = new Date(); // current Date

    if (
      closeReason === "Left Voicemail" ||
      closeReason === "Spoke to a person" ||
      closeReason === "Fax Resend"
    ) {
      prePopulatedDate.setDate(prePopulatedDate.getDate() + 7); // 1 wk out
    } else if (closeReason === "No Answer" || closeReason === "Other") {
      prePopulatedDate.setDate(prePopulatedDate.getDate() + 1); // next day
    }

    if (prePopulatedDate.getDay() === 0) {
      prePopulatedDate.setDate(prePopulatedDate.getDate() + 1); // Sunday -> Monday
    } else if (prePopulatedDate.getDay() === 6) {
      prePopulatedDate.setDate(prePopulatedDate.getDate() + 2); // Saturday -> Monday
    }

    setRescheduleDate(setTimeTo8am(prePopulatedDate));

    if (
      AgentWorkspaceConstants.closeReasonsForStateMapping[closeReason] &&
      props.activityState !== "Member Call"
    )
      setActivityState(
        AgentWorkspaceConstants.closeReasonsForStateMapping[closeReason]
          ? AgentWorkspaceConstants.closeReasonsForStateMapping[closeReason]
          : props.activityState
      );
    if (props.activityState === "Member Call")
      setIsStateDisabled(
        AgentWorkspaceConstants.closeReasonsForEnablingState.includes(
          closeReason
        )
          ? false
          : true
      );

    if (closeReason === "Fax Resend") getResendableSwitches(activitiesList);
  };

  const onSwitchDispositionChange = (setFieldValue, e) => {
    const switchDisposition = e.target.value;
    setFieldValue("switchDisposition", switchDisposition);
    setSwitchDispositionSelected(
      !isStringEmptyOrNull(switchDisposition) ? true : false
    );
    if (
      switchDisposition === "Prescriber Opt Out" ||
      switchDisposition === "Member Opt Out"
    ) {
      fetchEntityAssociatedSwitches(switchDisposition);
    } else {
      getCloseActivityDetails();
    }
  };

  const fetchEntityAssociatedSwitches = (closeReason) => {
    setEntitySwitchesLoading(true);
    sendAuthenticatedAsyncRequest(
      `/agent-workspace/get-entity-associated-switches/${activityId}`,
      "POST",
      closeReason,
      (r) => {
        const switchIdActivityIdList = r.data;
        const switchList = switchIdActivityIdList.map((sw) => {
          return {
            value: sw.activityUuid,
            label: sw.switchInfoId,
          };
        });
        setSwitchDetails(switchList);
        let allSwitchesActivities = [];
        switchList.forEach((sw) => allSwitchesActivities.push(sw.value));
        setActivitiesList(allSwitchesActivities);
        setSwitchOptions([]);
        setEntitySwitchesLoading(false);
      },
      (r) => {
        setEntitySwitchesLoading(false);
        showNotification({
          title: "Error fetching entity associated switches.",
          message: "Something went Wrong.",
          position: "tr",
          type: "error",
        });
      }
    );
  };

  const getResendableSwitches = (selectedActivitiesList) => {
    let switchList = [];
    setValidSwitchListForResendLoading(true);
    sendAuthenticatedAsyncRequest(
      `/agent-workspace/get-resendable-switches`,
      "POST",
      selectedActivitiesList,
      (r) => {
        const switchInfo = r.data;
        if (switchInfo.length > 0) {
          switchList = switchInfo.map((sw) => {
            return {
              value: sw.activityUuid,
              label: sw.switchInfoId,
            };
          });
        }
        setValidSwitchListForResend(switchList);
        setValidSwitchListForResendLoading(false);
      },
      (r) => {
        showNotification({
          title: "Failed to validate switch states!",
          message: "Something went wrong.",
          position: "tr",
          type: "error",
        });
        setValidSwitchListForResend([]);
      }
    );
  };

  const getRelevantCloseReasons = () => {
    const activityName = props.activityName;
    const activityType = props.activityType;
    const entityType = props.entityType;
    let baseReasons = AgentWorkspaceConstants.activityClosingReasons;
    if (
      activityType === "TASK" ||
      activityType === "PHONE_TASK" ||
      activityType === "FAX_TASK"
    ) {
      baseReasons = baseReasons.filter(
        (reason) => reason != "Move To Task List" && reason != HOLD_TIME_LIMIT
      );
    }
    if (activityType === "CALL") {
      baseReasons = baseReasons.filter(
        (reason) => reason != "Data Update Completed"
      );
    }
    if (activityName === "Call MEMBER") {
      baseReasons = baseReasons.filter((reason) => reason != "Member Call");
      baseReasons =
        AgentWorkspaceConstants.closeReasonsForCallMemberOnly.concat(
          baseReasons
        );
    }
    if (activityName !== "Call PHARMACY") {
      baseReasons = baseReasons.filter(
        (reason) =>
          reason != "Pharmacy Fill Awaiting Pickup" &&
          reason != "Pharmacy Confirmed Pickup" &&
          reason != "Schedule Prescriber Call"
      );
    }
    if (isLoggedInUserAWAdmin()) {
      return AgentWorkspaceConstants.closeReasonsForAdminOnly.concat(
        baseReasons
      );
    }
    return baseReasons;
  };

  const getRelevantSwitchDispositions = () => {
    let baseSwitchDispositions =
      AgentWorkspaceConstants.SWITCH_DISPOSITIONS_LIST;

    return baseSwitchDispositions;
  };

  const setRejectionReason = (rejectionReasonDropdown, setValue) => {
    const { value, name } = rejectionReasonDropdown;
    setValue(name, value);

    if (value) {
      const rejectionReasonCode = value.split("-")[0];
      const futureSwitchHandlerCode =
        rejectionReasonsToFutureSwitchHandlersMapping[rejectionReasonCode];
      let futureSwitchTreatment = "";

      for (let index in futureSwitchHandlers) {
        if (futureSwitchHandlers[index].startsWith(futureSwitchHandlerCode)) {
          futureSwitchTreatment = futureSwitchHandlers[index];
          break;
        }
      }
      setValue("futureSwitchTreatment", futureSwitchTreatment);
    }
  };

  const showSwitchDispositionDropDown = (closeReason) => {
    return (
      isStringEmptyOrNull(closeReason) ||
      AgentWorkspaceConstants.closeReasonsForEnablingSwitchDisposition.includes(
        closeReason
      )
    );
  };

  const showCallTempSelection = (closeReason) => {
    return (
      isStringEmptyOrNull(closeReason) ||
      AgentWorkspaceConstants.closeReasonsForDisplayingCallTemperature.includes(
        closeReason
      )
    ); //rxa-5798
  };

  const closeActivitySchema = (
    isCloseReasonSelected,
    isSwitchDispositionSelected
  ) =>
    Yup.object().shape(
      {
        closeReason: Yup.string()
          .max(255, "Max Length: 255")
          .nullable()
          .ensure()
          .when("holdFor30Days", {
            is: (holdFor30Days) =>
              !holdFor30Days && !isSwitchDispositionSelected,
            then: Yup.string().required("Required!"),
          }),
        note: Yup.string()
          .max(4096, "Max Length: 4096")
          .nullable()
          .ensure()
          .when(["closeReason", "switchDisposition", "doNotReschedule"], {
            is: (closeReason, switchDisposition, doNotReschedule) =>
              AgentWorkspaceConstants.closeReasonsWithMandatoryNotes.includes(
                closeReason
              ) ||
              AgentWorkspaceConstants.switchDispositionsWithMandatoryNotes.includes(
                switchDisposition
              ) ||
              doNotReschedule,
            then: Yup.string().required("Required!"),
          }),
        holdFor30Days: Yup.boolean().default(false),
        applyToMultipleSwitches: Yup.boolean().default(false),
        doNotReschedule: Yup.boolean().default(false),
        cashPay: Yup.boolean().default(false),
        verifiedPhone: Yup.boolean().default(false),
        verifiedFax: Yup.boolean().default(false),
        applyToAllEntities: Yup.boolean().default(false),
        assignAgent: Yup.boolean().default(false),
        assignedAgent: Yup.string()
          .max(255, "Max Length: 255")
          .nullable()
          .ensure()
          .when("assignAgent", {
            is: true,
            then: Yup.string().required("Required!"),
          }),
        switchList: Yup.array()
          .ensure()
          .when("applyToMultipleSwitches", {
            is: true,
            then: Yup.array().min(1, "Please select at least 1 switch"),
          }),
        rejectionReason: Yup.string()
          .max(255, "Max Length: 255")
          .nullable()
          .ensure()
          .when(["switchDisposition", "holdFor30Days"], {
            is: (switchDisposition, holdFor30Days) =>
              AgentWorkspaceConstants.switchDispositionsForDeclinedDisposition.includes(
                switchDisposition
              ) && !holdFor30Days,
            then: Yup.string().required("Required!"),
          }),
        futureSwitchTreatment: Yup.string()
          .max(255, "Max Length: 255")
          .nullable()
          .ensure()
          .when("switchDisposition", {
            is: (switchDisposition) =>
              AgentWorkspaceConstants.switchDispositionsForDeclinedDisposition.includes(
                switchDisposition
              ),
            then: Yup.string().required("Required!"),
          }),
        taskReason: Yup.string()
          .max(255, "Max Length: 255")
          .nullable()
          .ensure()
          .when("closeReason", {
            is: (closeReason) => "Move To Task List" === closeReason,
            then: Yup.string().required("Required!"),
          }),
        switchDisposition: Yup.string()
          .max(255, "Max Length: 255")
          .nullable()
          .ensure()
          .when("holdFor30Days", {
            is: (holdFor30Days) => !holdFor30Days && !isCloseReasonSelected,
            then: Yup.string().required("Required!"),
          }),
        callTemp: Yup.number()
          .nullable()
          .typeError("Required!")
          .when("closeReason", {
            is: (closeReason) =>
              closeReason === HOLD_TIME_LIMIT ||
              !AgentWorkspaceConstants.closeReasonsForDisplayingCallTemperature.includes(
                closeReason
              ),
            then: Yup.number().nullable(),
            otherwise: Yup.number()
              .required("Required!")
              .typeError("Required!")
              .notOneOf([null], "Required!"),
          }),
      },
      [
        [
          "closeReason",
          "note",
          "holdFor30Days",
          "applyToMultipleSwitches",
          "doNotReschedule",
          "switchList",
          "assignAgent",
          "assignedAgent",
          "switchDisposition",
          "verifiedPhone",
          "verifiedFax",
          "applyToAllEntities",
          "cashPay",
          "callTemp",
        ],
      ]
    );

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {callCounter >= AgentWorkspaceConstants.CallCounterLimit && (
            <Row>
              <Col md={12}>
                <Alert variant="danger">
                  <Alert.Heading as="h5">Notice!</Alert.Heading>
                  <p>
                    This activity has been rescheduled{" "}
                    <strong>{callCounter} times</strong>. You can hold the
                    activity for 30 days by checking the hold option below.
                  </p>
                </Alert>
              </Col>
            </Row>
          )}
          <Formik
            initialValues={{
              closeReason: "",
              note: latestAgentNote,
              holdFor30Days: false,
              applyToMultipleSwitches: false,
              doNotReschedule: false,
              switchList: [],
              rejectionReason: "",
              futureSwitchTreatment: "",
              assignAgent: false,
              assignedAgent: "",
              taskReason: "",
              switchDisposition: "",
              verifiedPhone: verifiedPhone,
              verifiedFax: verifiedFax,
              applyToAllEntities: false,
              cashPay: cashPay,
              callTemp: callTemp,
            }}
            onSubmit={(values, { setSubmitting, setValues }) => {
              submitForm(
                values,
                setSubmitting,
                props.modalRef.current.handleClose
              );
            }}
            validationSchema={closeActivitySchema(
              isCloseReasonSelected,
              isSwitchDispositionSelected
            )}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              dirty,
              isValid,
            }) => {
              useEffect(() => {
                if (
                  !AgentWorkspaceConstants.closeReasonsForDisplayingCallTemperature.includes(
                    values.closeReason
                  )
                ) {
                  values.callTemp = null;
                  setCallTemp(null);
                }
              }, [values.closeReason]);
              return (
                <>
                  <Form onSubmit={handleSubmit}>
                    <div className="boxed-div-blue">
                      {callCounter >=
                        AgentWorkspaceConstants.CallCounterLimit && (
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Check
                                label="Hold Activity for 30 days?"
                                checked={values.holdFor30Days}
                                onChange={() =>
                                  setFieldValue(
                                    "holdFor30Days",
                                    !values.holdFor30Days
                                  )
                                }
                                id="holdFor30Days"
                                name="holdFor30Days"
                                onBlur={handleBlur}
                                data-testid="input-close-activity-hold-activity"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                      {showUpdateActivityOption() && (
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label>Activity State</Form.Label>
                              <UpdateActivityState
                                isActionCallable={!isStateDisabled}
                                showPrescriberOptions={setShowPrescriberOptions()} // props => activityDetails.type, activity.name
                                activityState={activityState}
                                activityId={activityId}
                                onUpdateState={handleStateUpdate}
                                isMemberActivityCreatable={
                                  isMemberActivityCreatable
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      {!values.holdFor30Days && (
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Label>Activity Close Reason</Form.Label>
                              <FormSelect
                                value={values.closeReason}
                                onChange={(e) =>
                                  setInitialDateAndCloseReasonAndState(
                                    setFieldValue,
                                    e
                                  )
                                }
                                name="closeReason"
                                onBlur={handleBlur}
                                data-testid="input-close-activity-close-reason"
                              >
                                <option value="">Select Reason To Close</option>
                                {getRelevantCloseReasons()
                                  .sort()
                                  .map((reason, i) =>
                                    (reason === "Escalate" &&
                                      activityStatus === "ESCALATED") ||
                                    (reason === "De-escalate" &&
                                      !(activityStatus === "ESCALATED")) ||
                                    (reason === "Member Call" &&
                                      !isMemberActivityCreatable) ? null : (
                                      <option key={i} value={reason}>
                                        {reason}
                                      </option>
                                    )
                                  )}
                              </FormSelect>
                              <Form.Text className="text-small text-danger">
                                {errors.closeReason &&
                                  touched.closeReason &&
                                  errors.closeReason}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      {showSwitchDispositionDropDown(values.closeReason) &&
                        !values.holdFor30Days && (
                          <Row>
                            <Col md={12}>
                              <Form.Group>
                                <Form.Label>Switch Dispostion</Form.Label>
                                <FormSelect
                                  value={values.switchDisposition}
                                  onChange={(e) =>
                                    onSwitchDispositionChange(setFieldValue, e)
                                  }
                                  name="switchDisposition"
                                  onBlur={handleBlur}
                                  data-testid="input-close-activity-switch-disposition"
                                >
                                  <option value="">
                                    Select Switch Disposition
                                  </option>
                                  {getRelevantSwitchDispositions()
                                    .sort()
                                    .map((reason, i) => (
                                      <option key={i} value={reason}>
                                        {reason}
                                      </option>
                                    ))}
                                </FormSelect>
                                <Form.Text className="text-small text-danger">
                                  {errors.switchDisposition &&
                                    touched.switchDisposition &&
                                    errors.switchDisposition}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                          </Row>
                        )}

                      <Row>
                        <Col md={12}>
                          <Form.Group
                            hidden={
                              !AgentWorkspaceConstants.switchDispositionsForDeclinedDisposition.includes(
                                values.switchDisposition
                              ) || values.holdFor30Days
                            }
                          >
                            <Form.Label>Rejection Reason</Form.Label>
                            <FormSelect
                              id="close-activity-rejectionReason"
                              onChange={(e) =>
                                setRejectionReason(e.target, setFieldValue)
                              }
                              onBlur={handleBlur}
                              value={values.rejectionReason}
                              name="rejectionReason"
                              data-testid="input-close-activity-rejection-reason"
                            >
                              <option value="">Select Rejection Reason</option>
                              {rejectionReasons.map((r, i) => (
                                <option value={r} key={i}>
                                  {r}
                                </option>
                              ))}
                            </FormSelect>
                          </Form.Group>
                          <Form.Group hidden={true}>
                            <Form.Label>Future Switch Treatment</Form.Label>
                            <FormSelect
                              id="close-activity-futureSwitchTreatment"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.futureSwitchTreatment}
                              name="futureSwitchTreatment"
                              data-testid="input-close-activity-future-switch-treatment"
                            >
                              <option value="">
                                Select Future Switch Handling
                              </option>
                              {futureSwitchHandlers.map((r, i) => (
                                <option value={r} key={i}>
                                  {r}
                                </option>
                              ))}
                            </FormSelect>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <Form.Group
                            hidden={
                              "Move To Task List" !== values.closeReason ||
                              values.holdFor30Days
                            }
                          >
                            <Form.Label>Task Reason</Form.Label>
                            <FormSelect
                              id="close-activity-task-reason"
                              onChange={(e) =>
                                setFieldValue("taskReason", e.target.value)
                              }
                              onBlur={handleBlur}
                              value={values.taskReason}
                              name="taskReason"
                              data-testid="input-close-activity-task-reason"
                            >
                              <option value="">Select Task Reason</option>
                              {AgentWorkspaceConstants.TASK_REASONS_LIST.map(
                                (r, i) => (
                                  <option value={r} key={i}>
                                    {r}
                                  </option>
                                )
                              )}
                            </FormSelect>
                            <Form.Text className="text-small text-danger">
                              {errors.taskReason &&
                                touched.taskReason &&
                                errors.taskReason}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    <div className="boxed-div-blue">
                      {showAssignAgentOption(values.closeReason) && (
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Check
                                label="Assign agent to activity"
                                checked={values.assignAgent}
                                onChange={(e) => {
                                  handleAssignAgentCheckbox(
                                    setFieldValue,
                                    values.assignAgent
                                  );
                                }}
                                id="assignAgent"
                                name="assignAgent"
                                onBlur={handleBlur}
                                data-testid="input-close-activity-enable-assign-agent"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                      {showAssignAgentOption(values.closeReason) &&
                        values.assignAgent && (
                          <Row>
                            <Col md={12}>
                              <Form.Group>
                                <FormSelect
                                  id="assignedAgent"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.assignedAgent}
                                  name="assignedAgent"
                                  data-testid="input-close-activity-assign-agent"
                                >
                                  <option value="">Select Agent</option>
                                  {Object.entries(agentsList).map(
                                    ([key, value]) => (
                                      <option key={key} value={key}>
                                        {value}
                                      </option>
                                    )
                                  )}
                                </FormSelect>
                              </Form.Group>
                            </Col>
                          </Row>
                        )}

                      <Row>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Check
                              label="Verified Phone"
                              checked={verifiedPhone}
                              onChange={(e) => {
                                const newVerifiedPhoneValue = !verifiedPhone;
                                setFieldValue(
                                  "verifiedPhone",
                                  newVerifiedPhoneValue
                                );
                                setVerifiedPhone(newVerifiedPhoneValue);
                              }}
                              id="verifiedPhone"
                              name="verifiedPhone"
                              data-testid="input-close-activity-verify-phone"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group hidden={props.entityType === "MEMBER"}>
                            <Form.Check
                              label="Verified Fax"
                              checked={verifiedFax}
                              onChange={(e) => {
                                const newVerifiedFaxValue = !verifiedFax;
                                setFieldValue(
                                  "verifiedFax",
                                  newVerifiedFaxValue
                                );
                                setVerifiedFax(newVerifiedFaxValue);
                              }}
                              id="verifiedFax"
                              name="verifiedFax"
                              data-testid="input-close-activity-verify-fax"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Check
                              label={`Apply to all ${props.entityType.toLowerCase()}s`}
                              checked={values.applyToAllEntities}
                              onChange={(e) => {
                                setFieldValue(
                                  "applyToAllEntities",
                                  !values.applyToAllEntities
                                );
                              }}
                              id="applyToAllEntities"
                              name="applyToAllEntities"
                              data-testid="input-close-activity-verify-all-entities"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      {showRescheduleDateField(
                        values.closeReason,
                        values.holdFor30Days
                      ) && (
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Check
                                label="Do Not Reschedule"
                                checked={values.doNotReschedule}
                                onChange={(e) => {
                                  setFieldValue(
                                    "doNotReschedule",
                                    !values.doNotReschedule
                                  );
                                }}
                                id="doNotReschedule"
                                name="doNotReschedule"
                                data-testid="input-close-activity-do-not-reschedule"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      {showCashPayCheckbox(values.closeReason) && (
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Check
                                label="Cash Pay"
                                checked={cashPay}
                                onChange={(e) => {
                                  const newCashPayValue = !cashPay;
                                  setFieldValue("cashPay", newCashPayValue);
                                  setCashPay(newCashPayValue);
                                }}
                                id="cashPay"
                                name="cashPay"
                                data-testid="input-close-activity-cash-pay"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      {showGroupSaveOption(
                        values.closeReason,
                        values.switchDispositions,
                        values.holdFor30Days,
                        values.cashPay
                      ) && (
                        <Row>
                          <Col md={12}>
                            <Form.Group>
                              <Form.Check
                                label="Apply to multiple switches"
                                checked={values.applyToMultipleSwitches}
                                onChange={(e) => {
                                  handleGroupSaveCheckbox(
                                    values.closeReason,
                                    setFieldValue,
                                    values.applyToMultipleSwitches
                                  );
                                }}
                                id="applyToMultipleSwitches"
                                name="applyToMultipleSwitches"
                                data-testid="input-close-activity-enable-multi-switch"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      {values.applyToMultipleSwitches && !values.cashPay && (
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <div data-testid="input-close-activity-multi-switch">
                                <Select
                                  id="switchList"
                                  name="switchList"
                                  options={switchOptions}
                                  onChange={(options) =>
                                    onMultiSelectChange(
                                      values.closeReason,
                                      setFieldValue,
                                      options
                                    )
                                  }
                                  onBlur={handleBlur}
                                  placeholder="Select switches"
                                  isMulti={true}
                                  value={values.switchList}
                                  styles={customStyles}
                                  closeMenuOnSelect={false}
                                  data-testid="input-close-activity-multi-switch"
                                />
                              </div>
                              <Form.Text className="text-small text-danger">
                                {errors.switchList && errors.switchList}
                              </Form.Text>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                    </div>

                    <div className="boxed-div-blue">
                      {showRescheduleDateField(
                        values.closeReason,
                        values.holdFor30Days,
                        values.doNotReschedule
                      ) && (
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <FormLabel>Reschedule Date & Time</FormLabel>
                              <div data-testid="input-close-activity-reschdule-datetime">
                                <DateTimePicker
                                  onChange={onDateChange}
                                  value={rescheduleDate}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>
                              Notes{" "}
                              <OpenModalButtonIcon
                                style={{ display: "inline" }}
                                icon={<BsInfoCircle />}
                                toolTipText={
                                  "Close reason will be automatically appended to the start of your note."
                                }
                                placement={"right"}
                              />
                            </Form.Label>
                            <Form.Control
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.note}
                              name="note"
                              as="textarea"
                              data-testid="input-close-activity-notes"
                            />
                            <Form.Text className="text-small text-danger">
                              {errors.note && touched.note && errors.note}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>

                      {showCallTempSelection(values.closeReason) && (
                        <Row>
                          <Col md={12}>
                            <Form.Label>Feedback</Form.Label>
                            <Row>
                              {AgentWorkspaceConstants.callGroupTemperature.map(
                                (emoji, index) => {
                                  const IconComponent = emoji.icon;
                                  return (
                                    <IconComponent
                                      key={index}
                                      size={20}
                                      id={emoji.id}
                                      style={{
                                        flexShrink: 0,
                                        width: "auto",
                                        cursor: "pointer",
                                        opacity: callTemp
                                          ? callTemp === emoji.value
                                            ? 1
                                            : 0.5
                                          : 0.5,
                                        borderRadius: "50%",
                                        color: emoji.color,
                                      }}
                                      onClick={() => {
                                        setFieldValue("callTemp", emoji.value);
                                        setCallTemp(emoji.value);
                                      }}
                                    />
                                  );
                                }
                              )}
                            </Row>
                            <Form.Text className="text-small text-danger">
                              {errors.callTemp && errors.callTemp}
                            </Form.Text>
                          </Col>
                        </Row>
                      )}
                    </div>

                    {showConfirmDispositionDialog(
                      values.closeReason,
                      values.switchDisposition,
                      values.holdFor30Days,
                      values.verifiedPhone,
                      values.verifiedFax
                    ) || activitiesList.length > 1 ? (
                      <Row>
                        <Col md={4} className="bottom-spacer">
                          <ButtonWithPopover
                            placement="auto"
                            customRef={"closeConfimationBwp"}
                            buttonClassName="btn-block btn-text-white"
                            buttonVariant="success"
                            buttonText="Confirm"
                            buttonIsDisabled={
                              isSubmitting || !(dirty && isValid)
                            }
                            buttonIsBlock={true}
                            popoverTitle="Close Switch"
                            buttonId="switchdetails-btn-approve-popover"
                            dataTestId="btn-close-activity-submit"
                          >
                            {values.closeReason === "Fax Resend" ? (
                              validSwitchListForResend.length > 0 ? (
                                <>
                                  <p className="confirmation-text">
                                    You are about to <b>resend</b> the following
                                    switch(es):{" "}
                                  </p>
                                </>
                              ) : null
                            ) : values.closeReason === "Prescriber Opt Out" ||
                              values.closeReason === "Member Opt Out" ? (
                              <>
                                <p className="confirmation-text">
                                  You have chosen to Opt-Out{" "}
                                  <b>
                                    {values.closeReason === "Prescriber Opt Out"
                                      ? props.prescriberName
                                      : props.memberNumber}
                                  </b>{" "}
                                  from the entire RazorMetrics savings program
                                  for the following switch(es).
                                </p>
                              </>
                            ) : (AgentWorkspaceConstants.closeReasonsForClosingCallActivity.includes(values.closeReason) && props.entityType === "PRESCRIBER") ? ( 
                              <p className="confirmation-text">
                                  You are about to Close all Open and New Call Activities 
                                  of these switch(es):
                                <ul>
                                  {activityData.map((item) => (
                                    <li key={item.activityUuid}>
                                     <b>{item.concatenatedInfo}, {props.prescriberName} </b>
                                    </li>
                                  ))}
                                  {switchDetails.map((sw, idx) => {
                                  const condition1 = idx < switchDetails.length;
                                  const condition2 = activitiesList.includes(
                                    sw.value
                                  );
                                  const existInActivityData = activityData.some(info => sw.label.includes(info.concatenatedInfo))
                                  
                                  const finalCondition =
                                    (condition1 && condition2) 
                                  if (finalCondition && !existInActivityData) {
                                    return entitySwitchesLoading ? (
                                      <Spinner />
                                    ) : (
                                      <li key={sw.value}>
                                        <b>{sw.label}</b>
                                      </li>
                                    );
                                  }
                                })}
                                </ul>
                                </p>
                              ): AgentWorkspaceConstants.switchDispositionsForChangeDisposition.includes(
                                values.switchDisposition
                              ) ? (
                              <>
                                <p className="confirmation-text">
                                  You are about to change the disposition of the
                                  following switch(es) with close reason:{" "}
                                  <strong>
                                    {values.holdFor30Days
                                      ? "Hold for 30 days"
                                      : values.switchDisposition}
                                  </strong>
                                  .
                                </p>
                              </>
                            ) : values.closeReason === "Gatekeeper" ? (
                              props.entityType === "MEMBER" ? (
                                <p className="confirmation-text">
                                  {" "}
                                  <b>MEMBER</b> activity cannot be closed as
                                  Gatekeeper.
                                </p>
                              ) : (
                                <p className="confirmation-text">
                                  You are about to close all switch activities
                                  tied to&nbsp;
                                  <strong>
                                    {props.entityType === "PRESCRIBER"
                                      ? props.prescriberName
                                      : props.pharmacyName}
                                  </strong>
                                  .
                                </p>
                              )
                            ) : values.verifiedPhone || values.verifiedFax ? (
                              <p className="confirmation-text">
                                You have selected{" "}
                                <b>
                                  {props.entityType}:{" "}
                                  {values.verifiedPhone ? "phone" : ""}{" "}
                                  {values.verifiedPhone && values.verifiedFax
                                    ? " & "
                                    : ""}{" "}
                                  {values.verifiedFax ? "fax" : ""}{" "}
                                </b>{" "}
                                as verified number(s). Do you want to verify
                                these number(s)?
                              </p>
                            ) : (
                              <p className="confirmation-text">
                                You are about to close the activities of the
                                following switch(es) with close reason:{" "}
                                <strong>
                                  {values.holdFor30Days
                                    ? "Hold for 30 days"
                                    : values.closeReason}
                                </strong>
                                .
                              </p>
                            )}

                            {values.closeReason === "Fax Resend" ? (
                              validSwitchListForResendLoading ? (
                                <div className="rm-horizontal-center">
                                  <Spinner size="sm" />
                                </div>
                              ) : validSwitchListForResend.length > 0 ? (
                                <div className="rm-notes-activity">
                                  {validSwitchListForResend.map((sw, idx) => {
                                    if (
                                      idx < switchDetails.length &&
                                      activitiesList.includes(sw.value)
                                    ) {
                                      return (
                                        <li key={sw.value}>
                                          <b>SW {sw.label}</b>
                                        </li>
                                      );
                                    }
                                  })}
                                </div>
                              ) : null
                            ) : (
                              <div className="rm-notes-activity">
                                {switchDetails.map((sw, idx) => {
                                  const condition1 = idx < switchDetails.length;
                                  const condition2 = activitiesList.includes(
                                    sw.value
                                  );
                                  const condition3 =
                                    values.closeReason !== "Gatekeeper";
                                  const condition4 =
                                    values.closeReason === "Prescriber Opt Out";
                                  const condition5 =
                                    values.closeReason === "Member Opt Out";
                                  const condition6 =
                                   values.closeReason !== "Move To Task List";
                                  const finalCondition =
                                    (condition1 && condition2 && condition3 && condition6) ||
                                    condition4 ||
                                    condition5;
                                  if (finalCondition) {
                                    return entitySwitchesLoading ? (
                                      <Spinner />
                                    ) : (
                                      <li key={sw.value}>
                                        <b>{sw.label}</b>
                                      </li>
                                    );
                                  }
                                })}
                              </div>
                            )}

                            {values.closeReason === "Fax Resend" ? (
                              isCollectionNullOrEmpty(
                                validSwitchListForResend
                              ) ? (
                                validSwitchListForResendLoading ? (
                                  <p className="small-label-notes">
                                    validating switches...
                                  </p>
                                ) : (
                                  <span className="confirmation-text">
                                    The selected switches cannot be resent as
                                    they are in an invalid state.
                                  </span>
                                )
                              ) : (
                                <p className="confirmation-text">
                                  Are you sure you want to resend these
                                  switches?
                                </p>
                              )
                            ) : values.closeReason === "Prescriber Opt Out" ||
                              values.closeReason === "Member Opt Out" ? (
                              <p className="confirmation-text">
                                Do you want to to continue?
                              </p>
                            ) : AgentWorkspaceConstants.switchDispositionsForChangeDisposition.includes(
                                values.switchDisposition
                              ) ? (
                              <p className="confirmation-text">
                                Are you sure you want to save the disposition?
                              </p>
                            ) : values.closeReason === "Gatekeeper" &&
                              props.entityType === "MEMBER" ? (
                              <p className="confirmation-text">
                                Please select another close reason.
                              </p>
                            ) : (
                              <p className="confirmation-text">
                                Do you want to to continue?
                              </p>
                            )}

                            {values.closeReason === "Fax Resend" &&
                            isCollectionNullOrEmpty(
                              validSwitchListForResend
                            ) ? null : values.closeReason === "Gatekeeper" &&
                              props.entityType === "MEMBER" ? null : (
                              <div className="align-content-right">
                                <Button
                                  id="switchdetails-btn-approve"
                                  type="submit"
                                  block="true"
                                  disabled={
                                    values.closeReason === "Fax Resend"
                                      ? isCollectionNullOrEmpty(
                                          validSwitchListForResend
                                        ) ||
                                        validSwitchListForResendLoading === true
                                      : isSubmitting
                                  }
                                  onClick={handleSubmit}
                                  className="left-spacer btn-text-white"
                                  variant="success"
                                  data-testid="btn-close-activity-popover-submit"
                                >
                                  {isSubmitting ? "Updating ... " : "Yes"}
                                </Button>
                              </div>
                            )}
                          </ButtonWithPopover>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="top-spacer">
                        <Col md={4} className="bottom-spacer">
                          <Button
                            type="submit"
                            variant="success"
                            className="btn-block btn-text-white"
                            block="true"
                            disabled={isSubmitting || !(dirty && isValid)}
                            data-testid="btn-close-activity-submit"
                          >
                            {isSubmitting ? "Saving ... " : "Save & Update"}
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Form>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
};

export default withRouter(CloseActivty);
