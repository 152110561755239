import { createSlice } from "@reduxjs/toolkit";
import {
  getPawDsgListRequestAction,
  getPawDsgListSuccessAction,
  getPawDsgListFailureAction,
  changePawDSGOwnerFailureAction,
  changePawDSGOwnerRequestAction,
  changePawDSGOwnerSuccessAction,
} from "./PawDsgs.actions";
import { DEFAULT_FILTER, DEFAULT_SORT } from "./PawDsgs.contants";
export const INITAL_STATE = {
  data: [],
  totalPages: 0,
  pageSize: 20,
  page: 0,
  hasError: false,
  isLoading: false,
  totalElements: "loading",
  sorted: DEFAULT_SORT,
  filtered: DEFAULT_FILTER,
  isDefaultFilter: true,
  resized: [],
  expanded: {},
  changeOwnerStatus: "",
};

export const pawDSGSlice = createSlice({
  name: "pawDsgs",
  initialState: INITAL_STATE,
  reducers: {
    getPawDsgListSuccess: (state, action) => {
      state.data = action.payload.records;
      state.totalPages = Math.ceil(
        action.payload.totalElements / action.payload.pageSize
      );
      state.pageSize = action.payload.pageSize;
      state.page = action.payload.page;
      state.sorted = action.payload.sorted;
      state.filtered = action.payload.filtered;
      state.totalElements = action.payload.totalElements;
      state.hasError = false;
      state.isLoading = false;
    },
    getPawDsgListFailure: (state, action) => {
      state.isLoading = false;
      state.hasError = true;
      state.totalElements = "Failed";
    },
    getPawDsgListLoading: (state, action) => {
      state.isLoading = true;
      state.isDefaultFilter = false;
      state.totalElements = "Loading";
    },
    changePawDSGOwnerRequest: (state, action) => {
      state.changeOwnerStatus = "";
    },
    changePawDSGOwnerSuccess: (state, action) => {
      state.changeOwnerStatus = "Successful";
    },
    changePawDSGOwnerFailure: (state, action) => {
      state.changeOwnerStatus = "Failed";
    },
    RESET_PAGE_TO_ZERO: (state, action) => {
      state.page = 0;
    },
    RESET_NOTIFICATION_STATUS: (state, action) => {
      state.changeOwnerStatus = "";
    },
    RESET_ERROR_FIELD:(state, action) => {
      state.hasError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle the pending, fulfilled, and rejected states of the "DRUG_GROUP" RSAA action.
      .addCase(getPawDsgListRequestAction, (state, action) => {
        pawDSGSlice.caseReducers.getPawDsgListLoading(state, action);
      })
      .addCase(getPawDsgListSuccessAction, (state, action) => {
        pawDSGSlice.caseReducers.getPawDsgListSuccess(state, action);
      })
      .addCase(getPawDsgListFailureAction, (state, action) => {
        pawDSGSlice.caseReducers.getPawDsgListFailure(state, action);
      })
      .addCase(changePawDSGOwnerRequestAction, (state, action) => {
        pawDSGSlice.caseReducers.changePawDSGOwnerRequest(state, action);
      })
      .addCase(changePawDSGOwnerSuccessAction, (state, action) => {
        pawDSGSlice.caseReducers.changePawDSGOwnerSuccess(state, action);
      })
      .addCase(changePawDSGOwnerFailureAction, (state, action) => {
        pawDSGSlice.caseReducers.changePawDSGOwnerFailure(state, action);
      });
  },
});

export const {
  getPawDsgListSuccess,
  getPawDsgListFailure,
  getPawDsgListLoading,
  changePawDSGOwnerRequest,
  changePawDSGOwnerSuccess,
  changePawDSGOwnerFailure,
  RESET_PAGE_TO_ZERO,
  RESET_NOTIFICATION_STATUS,
  RESET_ERROR_FIELD
} = pawDSGSlice.actions;
export const pawDSGState = (state) => state.pawDsgs;
export default pawDSGSlice.reducer;
