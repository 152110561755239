import DrugDetails from "components/PawGroups/DrugDetails";
import SwitchDetails from "components/PawGroups/SwitchDetails";
import DivLoader from "layouts/components/DivLoader";
import ErrorCodes from "lookup/ErrorCodes";
import React from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { showNotification } from "services/NotificationService";
import { getPAWGroupDetail } from "../PAWGroupDisplayBar/PAWGroupDisplayBar.actions";
import PawGroupDisplayBar from "../PAWGroupDisplayBar/PawGroupDisplayBar";
import { getIfPublishStatus } from "../PawGroup/PawGroup.constants";
import {
  autoAssignDrugGroup,
  autoReviewDrugGroup,
  enableApprovalModalState,
  getDrugGroupDetail,
  getDrugGroupSwitches,
  getNextDrugGroup,
  reopenDrugGroupTrackerStaus,
  resetDrugGroupStatusState,
  resetSwitchDetailFetchFlag,
  submitDrugGroupForReview,
  unassignDrugGroup,
  updateWorkflowItemStatus,
} from "./DrugGroupDetails.actions";
import "./DrugGroupDetails.css";

let DRUG_GROUP_ID = null;

const DrugGroupDetails = ({ history, match }) => {
  const dispatch = useDispatch();
  const { pawGroupId, drugGroup } = useParams();
  const drugDetails = useSelector(
    (state) => state.drugGroupDetail.drugGroupDetail
  );
  const currentSwitchPage = useSelector(
    (state) => state.drugGroupDetail.currentSwitchPage
  );
  const totalSwitches = useSelector(
    (state) => state.drugGroupDetail.totalSwitches
  );
  const currentSwitch = useSelector((state) => state.drugGroupDetail.switch);
  const pawGroupStatus = useSelector((state) => state.pawGroupDetail.status);
  const approvalCount = drugDetails.remainingSwitchCountForAutoApproval;
  const rejectionCount = drugDetails.remainingSwitchCountForAutoRejection;
  const statusAction = useSelector(
    (state) => state.drugGroupDetail.statusAction
  );
  const [approvalModel, showApprovalModel] = React.useState(false);
  const statusIsLoading = useSelector(
    (state) => state.drugGroupDetail.statusLoader
  );
  const isSwitchUpdateRequired = useSelector(
    (state) => state.drugGroupDetail.isSwitchUpdateRequired
  );
  const fetchDrugGroupError = useSelector(
    (state) => state.drugGroupDetail.fetchDrugGroupError
  );
  const error = useSelector((state) => state.drugGroupDetail.error);
  const pawGroupPublishedStatus = getIfPublishStatus(pawGroupStatus);
  const isApprovalModalActivated = useSelector(
    (state) => state.drugGroupDetail.isApprovalModalActivated
  );
  const drugDetailLoader = useSelector(
    (state) => state.drugGroupDetail.drugDetailLoader
  );

  const switchLoader = useSelector(
    (state) => state.drugGroupDetail.switchLoader
  );
  React.useEffect(() => {
    if (
      !drugDetailLoader &&
      isSwitchUpdateRequired &&
      drugDetails.drugGroupId != DRUG_GROUP_ID
    ) {
      history.replace(
        `/paw/paw-groups/${pawGroupId}/drug-groups/${drugDetails.drugGroupId}`
      );
      dispatch(getDrugGroupSwitches(drugDetails.drugGroupId, 0));
    } else if (
      !drugDetailLoader &&
      isSwitchUpdateRequired &&
      drugDetails.drugGroupId == DRUG_GROUP_ID
    ) {
      dispatch(resetSwitchDetailFetchFlag());
    }
  }, [isSwitchUpdateRequired, drugDetailLoader]);

  React.useEffect(() => {
    if (fetchDrugGroupError == ErrorCodes.NO_DRUG_GROUP_AFTER_UUID) {
      showNotification({
        title: "No more Drug Groups to review!",
        message: fetchDrugGroupError,
        position: "tr",
        type: "success",
      });
    } else if (fetchDrugGroupError) {
      showNotification({
        title: "Could not move to next Drug Group!",
        message: "Something went wrong.. Please try again later!",
        position: "tr",
        type: "error",
      });
    }
  }, [fetchDrugGroupError]);
  React.useEffect(() => {
    if (statusAction && statusAction != "AUTO_ASSIGN" && !statusIsLoading) {
      if (error) {
        showNotification({
          title: "Action Failed!",
          message: "Could not update the status of the switch.",
          position: "tr",
          type: "error",
        });
      } else {
        showNotification({
          title: "Action Successful!",
          message: "Successfully updated the status of the switch.",
          position: "tr",
          type: "success",
        });
      }
    }
  }, [statusIsLoading, statusAction]);

  React.useEffect(() => {
    return () => {
      DRUG_GROUP_ID = null;
      dispatch(resetDrugGroupStatusState());
    };
  }, []);

  React.useEffect(() => {
    dispatch(getDrugGroupDetail(pawGroupId, drugGroup));
    dispatch(getDrugGroupSwitches(drugGroup, 0));
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getDrugGroupDetail(pawGroupId, drugGroup));
    dispatch(getPAWGroupDetail(pawGroupId));
  }, [statusAction]);

  React.useEffect(() => {
    if (DRUG_GROUP_ID == null || DRUG_GROUP_ID != drugDetails.drugGroupId) {
      if (
        Object.keys(drugDetails).length > 0 &&
        drugDetails.ownerId == null &&
        drugDetails.status != "AUTO_REJECTED_BY_DSG"
      ) {
        const drugGroupTrackerId = drugDetails.drugGroupTrackerId;
      }
      DRUG_GROUP_ID = drugDetails.drugGroupId;
    }
  }, [drugDetails]);

  const goForManualReview = () => {
    dispatch(enableApprovalModalState(true));
    showApprovalModel(false);
  };

  const goToNextSwitch = () => {
    const nextSwitch = currentSwitchPage + 1;
    if (nextSwitch < totalSwitches) {
      dispatch(getDrugGroupSwitches(drugGroup, nextSwitch));
    }
  };

  const approveSwitch = (wfItemId, status) => {
    const remainingSwitchCountForAutoApproval =
      drugDetails.remainingSwitchCountForAutoApproval - 1;
    if (remainingSwitchCountForAutoApproval <= 0 && !isApprovalModalActivated) {
      showApprovalModel(true);
    }
    dispatch(updateWorkflowItemStatus(wfItemId, "", status));
    goToNextSwitch();
  };

  const rejectSwitch = (wfItemId, status, rejectionReason, comments) => {
    const remainingSwitchCountForAutoRejection =
      drugDetails.remainingSwitchCountForAutoRejection - 1;
    if (
      remainingSwitchCountForAutoRejection <= 0 &&
      !isApprovalModalActivated
    ) {
      showApprovalModel(true);
    }
    dispatch(
      updateWorkflowItemStatus(wfItemId, rejectionReason, status, comments)
    );
    goToNextSwitch();
  };

  const goBackASwitch = () => {
    const goBackASwitch = currentSwitchPage - 1;
    if (goBackASwitch >= 0) {
      dispatch(getDrugGroupSwitches(drugGroup, goBackASwitch));
    }
  };

  const getModalHeaderText = () => {
    if (approvalCount > rejectionCount) {
      return "We will automatically reject next switches. Are you sure you want to do that?";
    } else {
      return "We will automatically approve next switches. Are you sure you want to do that?";
    }
  };

  const getModalTileText = () => {
    if (approvalCount > rejectionCount) {
      return "Auto Rejection";
    } else {
      return "Auto Approval";
    }
  };

  const onAutoReview = () => {
    const drugGroupTrackerId = drugDetails.drugGroupTrackerId;
    dispatch(autoReviewDrugGroup(drugGroupTrackerId));
    if (approvalModel == true) {
      showApprovalModel(false);
    }
  };

  const onSubmitReview = (referrerNotes, referrerId) => {
    const drugGroupTrackerId = drugDetails.drugGroupTrackerId;
    dispatch(
      submitDrugGroupForReview(drugGroupTrackerId, referrerNotes, referrerId)
    );
  };

  const onUnclaimPAW = () => {
    const drugGroupTrackerId = drugDetails.drugGroupTrackerId;
    dispatch(unassignDrugGroup(drugGroupTrackerId));
    history.goBack();
  };

  const fetchNextDrugGroup = () => {
    dispatch(getNextDrugGroup(pawGroupId, DRUG_GROUP_ID));
  };

  const reOpenDrugGroup = (reopenReason) => {
    const drugGroupTrackerId = drugDetails.drugGroupTrackerId;
    dispatch(reopenDrugGroupTrackerStaus(drugGroupTrackerId, reopenReason));
  };

  return (
    <Container fluid>
      <Modal
        show={approvalModel}
        onHide={() => {
          showApprovalModel(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTileText()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{getModalHeaderText()}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              goForManualReview();
            }}
          >
            No, I want to review more
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onAutoReview();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <PawGroupDisplayBar showGroupStats history={history} />
      <DivLoader isLoading={drugDetailLoader || switchLoader} />
      {drugDetailLoader || switchLoader ? null : (
        <Row>
          {Object.keys(drugDetails).length > 0 && currentSwitch ? (
            <DrugDetails
              getNextDrugGroup={fetchNextDrugGroup}
              target={currentSwitch.targetOptions}
              approvalCount={approvalCount}
              rejectionCount={rejectionCount}
              onSubmitReview={onSubmitReview}
              statusUpdate={statusAction}
              onAutoReview={onAutoReview}
              onUnclaimPAW={onUnclaimPAW}
              onReopenDrugGroup={reOpenDrugGroup}
              pawGroupStatus={pawGroupPublishedStatus}
            />
          ) : null}
          {currentSwitch ? (
            <SwitchDetails
              switchdetails={currentSwitch}
              currentIndex={currentSwitchPage}
              totalCount={totalSwitches}
              moveIndexForward={() => {
                goToNextSwitch();
              }}
              moveIndexBackward={() => {
                goBackASwitch();
              }}
              approveSwitch={approveSwitch}
              rejectSwitch={rejectSwitch}
              status={
                pawGroupPublishedStatus
                  ? null
                  : currentSwitch.status == "PAW_REVIEW"
                  ? drugDetails.status
                  : currentSwitch.status
              }
            />
          ) : null}
        </Row>
      )}
    </Container>
  );
};
export default DrugGroupDetails;
