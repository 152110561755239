import RawList from "../views/Raw/RawList/RawList";
import SwitchDisposition from "../views/Raw/SwitchDisposition/SwitchDisposition";
import Landing from "../views/Landing/Landing";
import Admin from "views/Admin/Admin";
import UserList from "views/Admin/UserList/UserList";
import SwitchList from "views/Switch/SwitchList/SwitchList";
import SwitchDetails from "views/Switch/SwitchDetails/SwitchDetails";
import CycleList from "views/Cycle/CycleList/CycleList";
import BatchList from "../views/Batch/BatchList/BatchList";
import SwimSwitchList from "../views/AgentWorkspace/SwimSwitchList/SwimSwitchList";
import DynamicSwitchGroupList from "views/Cat/DynamicSwitchGroupList/DynamicSwitchGroupList";
import UpdatedDynamicSwitchGroupList from "views/Cat/CompareCodexVersion/UpdatedDynamicSwitchGroupList";
import DynamicSwitchGroup from "views/Cat/DynamicSwitchGroup/DynamicSwitchGroup";
import DrugSearch from "views/Cat/DrugSearch/DrugSearch";
import CodexVersion from "views/Cat/CodexVersion/CodexVersion";
import ChangePassword from "views/User/ChangePassword";
import BulkList from "views/Schedular/BulkList/BulkList";
import TableOfContents from "views/Analytics/TableOfContents/TableOfContents";
import { hasRoleIn } from 'rbac/RbacHelper';
import { ROUTES } from 'rbac/RbacContentProvider';
import ROLES from 'rbac/Roles.json';
import DynamicSwitchGroupDetails from 'views/Cat/DynamicSwitchGroupDetails/DynamicSwitchGroupDetails';
import UnprocessedFaxList from 'views/Switch/UnprocessedFaxList/UnprocessedFaxList';
import UnprocessedFaxDetails from 'views/Switch/UnprocessedFaxDetails/UnprocessedFaxDetails';
import CallGroupDetails from 'views/AgentWorkspace/CallGroupDetails/CallGroupDetails';
import CallGroupList from 'views/AgentWorkspace/CallGroupList/CallGroupList';
import UpdatedDynamicSwitchGroupDetails from 'views/Cat/CompareCodexVersion/UpdatedDSGDetails/UpdatedDynamicSwitchGroupDetails';
import AgentDashboard from 'views/AgentWorkspace/AgentDashboard/AgentDashboard';
import TranscriberList from "views/AgentWorkspace/TranscriberList/TranscriberList";
import TranscribeDetails from "views/AgentWorkspace/TranscribeDetails/TranscribeDetails";
import MedispanDrugs from 'views/Cat/MedispanDrugs/MedispanDrugs';
import ActiveRadar from 'views/Cat/ActiveRadar/ActiveRadar';
import NewDrug from 'views/Cat/NewDrug/NewDrug';
import PawGroup from 'views/Paw/PawGroup/PawGroup';
import DrugGroup from 'views/Paw/DrugGroup/DrugGroup';
import DrugGroupDetails from 'views/Paw/DrugGroupDetails/DrugGroupDetails';
import MemberList from "views/MemberProfile/MemberList/MemberList";
import MemberDetail from "views/MemberProfile/MemberDetail/MemberDetail";
import SchedularForm from "views/Schedular/SchedularForm";
import DashboardDetails from "views/Analytics/DashboardDetails/DashboardDetails";
import PawDsgList from "views/Paw/PawDsgs/PawDsgList";
// not to be used from outside this file directly, use accessor methods below
const getDashboardRoutes = () => {
  const catBasePath = "/cat";
  const catRoutes = [
    {
      name: "Select Codex Version",
      path: `${catBasePath}`,
      component: CodexVersion,
    },
    {
      name: "DSG",
      path: `${catBasePath}/codex/dsg`,
      component: DynamicSwitchGroupList,
    },
    {
      name: "Compare Codex",
      path: `${catBasePath}/codex/updated-dsg`,
      component: UpdatedDynamicSwitchGroupList
    },
    {
      name: "Drug Search",
      path: `${catBasePath}/codex/search-drugs`,
      component: DrugSearch,
      accessors: [ROLES.CODEX_SEARCH]
    },
    {
      name: "Medispan Drug Search",
      path: `${catBasePath}/codex/medispan-drugs`,
      component: MedispanDrugs
    },
    {
      name: "Active Radar Search",
      path: `${catBasePath}/codex/active-radar`,
      component: ActiveRadar
    },
    {
      name: "New Drug",
      path: `${catBasePath}/dynamic-switch-groups/:dynamicSwitchGroupId/add-new-drug`,
      component: NewDrug,
      excludeFromNavigation: true
    },
    {
      name: "Dynamic Switch Group Details",
      path: `${catBasePath}/dynamic-switch-groups/:dynamicSwitchGroup`,
      component: DynamicSwitchGroupDetails,
      excludeFromNavigation: true
    },
    {
      name: "Compare Dynamic Switch Group Details",
      path: `${catBasePath}/codex-version/:codexVersion/dynamic-switch-groups/:dynamicSwitchGroup`,
      component: UpdatedDynamicSwitchGroupDetails,
      excludeFromNavigation: true
    },
    {
      name: "Dynamic Switch Group",
      path: `${catBasePath}/codex/dsg/new`,
      component: DynamicSwitchGroup,
      excludeFromNavigation: true
    }
  ]

  const paw2_0BasePath = "/paw"
  const paw2_0Routes = [
    {
      name: "PAW Groups",
      path: `${paw2_0BasePath}/paw-groups`,
      component: PawGroup
    },
    {
      name: "Drug Groups",
      path: `${paw2_0BasePath}/paw-groups/:pawGroupId/drug-groups`,
      component: DrugGroup,
      excludeFromNavigation: true,
    },
    {
      name: "PAW DSGs",
      path: `${paw2_0BasePath}/paw-dsgs/`,
      component: PawDsgList,
      accessors: [ROLES.PAW_ADMIN],
    },
    {
      name: "Drug Group Details",
      path: `${paw2_0BasePath}/paw-groups/:pawGroupId/drug-groups/:drugGroup`,
      component: DrugGroupDetails,
      excludeFromNavigation: true,
    }
  ]

  const memberProfileBasePath = "/member";
  const memberProfileRoutes = [
    {
      name: "Member List ",
      path: `${memberProfileBasePath}/list`,
      component: MemberList,
    },
    {
      name: "Member Details",
      path: `${memberProfileBasePath}/:memberId/details`,
      component: MemberDetail,
      excludeFromNavigation: true,
    },
  ];

  const rawBasePath = '/raw';
  const rawRoutes = [
    {
      name: "Response List ",
      path: `${rawBasePath}/list`,
      component: RawList,
    },
    {
      name: "Response Details",
      path: `${rawBasePath}/:rawId`,
      component: SwitchDisposition,
      excludeFromNavigation: true,
    },
    {
      name: "Switch Details for Response",
      path: `${rawBasePath}/:rawId/wf/:wfItemId`,
      component: SwitchDetails,
      excludeFromNavigation: true,
    },
  ];

  const switchBasePath = "/switch";
  const unprocessedFaxBasePath = "/unprocessed-fax";
  const transcriptionBasePath = "/transcription"
  const switchRoutes = [
    {
      name: "Switch List",
      path: `${switchBasePath}/list`,
      component: SwitchList,
    },
    {
      name: "Unprocessed Fax List",
      path: `${unprocessedFaxBasePath}/list`,
      component: UnprocessedFaxList,
    },
    {
      name: "Transcriber Fax lists",
      path: `${transcriptionBasePath}/list`,
      component: TranscriberList,
    },
    {
      name: "Switch Details",
      path: `${switchBasePath}/:wfItemId`,
      component: SwitchDetails,
      excludeFromNavigation: true,
    },
    {
      name: "Switch Disposition",
      path: `${switchBasePath}/disposition/:rawId`,
      component: SwitchDisposition,
      excludeFromNavigation: true,
    },
    {
      name: "Transcriber Fax Details",
      path: `${transcriptionBasePath}/:faxId`,
      component: TranscribeDetails,
      excludeFromNavigation: true,
    },
    {
      name: "Unprocessed Fax Details",
      path: `${unprocessedFaxBasePath}/fax-details/:faxInfoId`,
      component: UnprocessedFaxDetails,
      excludeFromNavigation: true,
    },
  ];
  const schedulerBasePath = "/scheduler";
  const schedulerRoutes = [
    {
      name: "Scheduler",
      path: `${schedulerBasePath}`,
      component: SchedularForm,
    },
    {
      name: "Batch",
      path: `${schedulerBasePath}/batch/list`,
      component: BatchList,
    },
    {
      name: "Cycle",
      path: `${schedulerBasePath}/cycle/list`,
      component: CycleList,
    },
    {
      name:"Bulk Action",
      path: `${schedulerBasePath}/bulk-action/list`,
      component: BulkList,
    },
    {
      name: "Bulk Action Details",
      path: `${schedulerBasePath}/bulk-action/:bulkActionUuid`,
      component: SchedularForm,
      excludeFromNavigation: true,
    },
  ];
  const agentWorkspaceBasePath = "/agent-workspace";
  const agentWorkspaceRoutes = [
    {
      name: "Agent Dashboard",
      path: `${agentWorkspaceBasePath}/dashboard`,
      component: AgentDashboard,
      accessors: [ROLES.AW_CALL_ACTIVITY, ROLES.AW_TASK_ACTIVITY]
    },
    {
      name: "Call Group List",
      path: `${agentWorkspaceBasePath}/list`,
      component: CallGroupList,
      accessors: [ROLES.AW_CALL_ACTIVITY, ROLES.AW_TASK_ACTIVITY]
    },
    {
      name: "SWIM Switch List",
      path: `${agentWorkspaceBasePath}/swim-switch-list`,
      component: SwimSwitchList,
      accessors: [ROLES.AW_CALL_ACTIVITY, ROLES.AW_TASK_ACTIVITY]
    },
    {
      name: "Call Group Detail",
      path: `${agentWorkspaceBasePath}/call-group-details/:callGroupId`,
      component: CallGroupDetails,
      excludeFromNavigation: true,
    }
  ];

  const adminBasePath = "/admin";
  const adminRoutes = [
    {
      name: "Platform",
      path: `${adminBasePath}/platform`,
      component: Admin,
    },
    {
      name: "Users",
      path: `${adminBasePath}/users`,
      component: UserList,
      // accessors: [ROLES.AW_ADMIN],
    },
  ];

  const analyticsBasePath = "/analytics";
  const analyticsRoutes = [
    {
      name: "Table of Contents",
      path: `${analyticsBasePath}/table-of-contents`,
      component: TableOfContents
    },
    {
      name: "Dashboard Details",
      path: `${analyticsBasePath}/:dashboardId/details`,
      component: DashboardDetails,
      excludeFromNavigation: true,
    }
  ]

  /**
   * CONSIDERATIONS
   * A. For sidebar
   * Please consider this structuring is dependendent upon Sidebar component
   * 1. if you want the subroutes to be shown in sidebar for a route where excludeFromNavigation is TRUE, add baseRoute to parent
   * 2. parent route having subroutes can have path AND component attributes - upon selection, parent will be active
   * 3. if parent route does not have path AND component attributes, upon selecting that link from sidebar, first child route is opened and shown as active - upon selection, parent will be highlighted
   * 4. if child route does not have accessor, parent's accessor value will be used (NEED TO IMPLEMENT)
   * 5. if parent route does not have accossor, it's considered accessible by all routes
   *
   * B. For route rendering
   * 1. Need to add suport for full page components
   */

  return [
    {
      name: "Dashboard",
      path: "/dashboard",
      component: Landing,
      accessors: [ROLES.SWIM_USER],
    },
    {
      path: "/account/change-password",
      component: ChangePassword,
      excludeFromNavigation: true,
    },
    {
      name: "C.A.T.",
      subRoutes: catRoutes,
      basePath: catBasePath,
      accessors: [ROLES.CODEX_ENTITIES_CRUD, ROLES.CODEX_SEARCH]
    },
    {
      name: "P.A.W",
      subRoutes: paw2_0Routes,
      basePath: paw2_0BasePath,
      accessors: [ROLES.PAW_USER]
    },
    {
      name: "Responses",
      subRoutes: rawRoutes,
      basePath: rawBasePath,
      accessors: [ROLES.PAW_USER],
    },
    {
      name: "Switches",
      subRoutes: switchRoutes,
      basePath: switchBasePath,
      accessors: [ROLES.SWIM_USER],
    },
    {
      name: "Member Profiles",
      subRoutes: memberProfileRoutes,
      basePath: memberProfileBasePath,
      accessors: [ROLES.AW_CALL_ACTIVITY, ROLES.AW_TASK_ACTIVITY, ROLES.AW_ADMIN,ROLES.ROLE_PHI_ACCESS],
    },
    {
      name: "Agent Workspace",
      subRoutes: agentWorkspaceRoutes,
      basePath: agentWorkspaceBasePath,
      accessors: [ROLES.AW_CALL_ACTIVITY, ROLES.AW_TASK_ACTIVITY, ROLES.AW_ADMIN],
    },
    {
      name: "Scheduler",
      subRoutes: schedulerRoutes,
      basePath: schedulerBasePath,
      accessors: [ROLES.AW_ADMIN, ROLES.SWIM_USER],
    },
    {
      name: "Analytics",
      subRoutes: analyticsRoutes,
      basePath: analyticsBasePath,
      accessors: [ROLES.ANALYTICS_READER, ROLES.ANALYTICS_WRITER],

    },
    {
      name: "Admin",
      subRoutes: adminRoutes,
      basePath: adminBasePath,
      accessors: [ROLES.API],

    },
    // redirect needs to be at the last (heavy logic)
    {
      excludeFromNavigation: true,
      redirect: true,
      path: "/",
      to: ROUTES.provideRedirectRoute(), // this path needs to point to a valid path within dashboardRoutes array
    },
  ];
};

// iterate over routes and subRoutes and exclude all inaccessible routes
// based on logged in user's roles and accessors for each route
// What currently happens: if parent route accessor does not exists in user roles, without checking any child routes, this returns
// What should happen: child route accessor should superceed the parent route accessor
export const getRoutes = () => {
  const routes = [];

  getDashboardRoutes().forEach((r) => {
    if (!hasRoleIn(r.accessors)) return;
    const route = Object.assign({}, r);
    if (r.subRoutes) {
      const subRoutes = [];
      r.subRoutes.forEach((sr) => {
        if (!hasRoleIn(sr.accessors)) return;

        const subRoute = Object.assign({}, sr);
        subRoutes.push(subRoute);
      });
      route.subRoutes = subRoutes;
    }

    routes.push(route);
  });
  return routes;
};

export const flattenedRoutes = () => {
  const routes = [];
  getRoutes().forEach((route) => {
    if (route.path) routes.push({ ...route });
    if (route.subRoutes)
      route.subRoutes.forEach((subRoute) => routes.push({ ...subRoute }));
  });
  return routes;
};
