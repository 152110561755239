import React, { useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { MdLocalPhone } from 'react-icons/md';
import { sendAuthenticatedAsyncRequest } from 'services/AsyncRequestService';
import { showNotification } from 'services/NotificationService';

const ConnectCallButton = ({ label, body }) => {
  const [loading, setLoading] = useState(false);

  const sendRequest = () => {
    return new Promise((resolve, reject) => {
      sendAuthenticatedAsyncRequest(
        '/call-center/connect',
        'POST',
        body,
        (response) => resolve(response), 
        (error) => reject(error)
      );
    });
  };

  const onAWSConnectClick = useCallback(async () => {
    if (!body) {
      showNotification({
        title: 'Error',
        message: 'Invalid request body. Please try again.',
        position: 'tr',
        type: 'error',
      });
      return;
    }

    setLoading(true);
    try {
      const response = await sendRequest(); 
      if (response && response.status == 200) {
        showNotification({
          title: 'Action Successful!',
          message: 'Your call is being connected.',
          position: 'tr',
          type: 'success',
        });
      } else {
        showNotification({
          title: 'Call Failed',
          message: 'Unexpected response from the Lambda. Please try again.',
          position: 'tr',
          type: 'error',
        });
      }
    } catch (error) {
      showNotification({
        title: 'Call Failed',
        message: 'An error occurred while processing your request. Please try again.',
        position: 'tr',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [body, label]);

  return (
    <Button
      style={{ background: 'none', border: 'none', cursor: loading ? 'not-allowed' : 'pointer' }}
      aria-label="Call"
      onClick={onAWSConnectClick}
      disabled={loading}
    >
      <MdLocalPhone size={18} color={loading ? 'gray' : 'blue'} />
    </Button>
  );
};

export default ConnectCallButton;
