import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateInterventionList,
  updateIndexOnList,
  resetError,
} from "./SwitchCycleList.actions";
import { withRouter } from "react-router-dom";
import Col from "react-bootstrap/Col";
import {
  DEFAULT_SWIM_SORT,
  SWIM_CYCLE_FILTER,
  DEFAULT_FILTER,
} from "lookup/SwimConstants";
import "rsuite/dist/rsuite.min.css";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import { MdHelp } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import SwitchListCommon from "../SwitchList/SwitchListCommon";
import { COLUMN } from "../SwitchList/CreateColumnListForSwitchList";
import { isLoggedInUserBioSimilarUser } from "services/AWUtilityService";
import { SWITCH_NUMBER_OP } from "lookup/GlobalConstants";
import { SWITCH_NUMBER_STRING } from "lookup/GlobalConstants";

const COLUMN_LIST = [
  COLUMN.customerNameAbbreviation,
  COLUMN.clientNameAbbreviation,
  COLUMN.switchNumber,
  COLUMN.generatedDate,
  COLUMN.projectedSavings,
  COLUMN.cycleState,
  COLUMN.cycleStatus,
  COLUMN.hasOpenTaskActivity,
  COLUMN.hasPrescriberInReview,
  COLUMN.activityBlocked,
];

const DEFAULT_PAGE_SIZE = 15;

class SwitchCycleList extends React.Component {
  state = {
    isSelectAll: false,
    toggleAllSwitches: false,
    toggleDefaultFilters: true,
    wfItemIds: this.props.wfItemIds,
    resetTableSelection: false,
  };
  updateData = (state, preventUpdate) => {
    if (!preventUpdate) {
      const filteredForState = state.filtered.filter(
        (element) =>
          !(element.id === "state" && element.value.includes(DEFAULT_FILTER))
      );
      if (filteredForState.length !== state.filtered.length) {
        filteredForState.push(SWIM_CYCLE_FILTER.defaultStateFrontEnd);
        state.filtered = filteredForState;
      }

      const index_status = state.filtered.findIndex(
        (element) => element.id === "status"
      );

      if (index_status !== -1) {
        state.filtered[index_status].value = state.filtered[
          index_status
        ].value.map((value) =>
          typeof value === "string" ? value : value.name
        );
      }

      const switchNumberIndex = state.filtered.findIndex(
        (element) => element.id === "switchNumber"
      );
      if (switchNumberIndex !== -1) {
        state.filtered[switchNumberIndex].operator = SWITCH_NUMBER_OP;
        state.filtered[switchNumberIndex].id = SWITCH_NUMBER_STRING;
      }

      const filteredForStatus = state.filtered.filter(
        (element) =>
          !(element.id === "status" && element.value.includes(DEFAULT_FILTER))
      );
      if (filteredForStatus.length !== state.filtered.length) {
        filteredForStatus.push(SWIM_CYCLE_FILTER.defaultStatusFrontEnd);
        state.filtered = filteredForStatus;
      }
      const clientFilterIndex = state.filtered.findIndex(
        (element) => element.id === "clientNameAbbreviation"
      );
      if (clientFilterIndex !== -1) {
        state.filtered.splice(clientFilterIndex, 1);
      }
      const filteredForTaskIndex = state.filtered.findIndex(
        (element) =>
          element.id === "hasOpenTaskActivity" && element.value.includes("All")
      );
      if (filteredForTaskIndex !== -1) {
        state.filtered.splice(filteredForTaskIndex, 1);
      }
      const filteredForHasPrescriberInReviewIndex = state.filtered.findIndex(
        (element) =>
          element.id === "hasPrescriberInReview" &&
          element.value.includes("All")
      );
      if (filteredForHasPrescriberInReviewIndex !== -1) {
        state.filtered.splice(filteredForHasPrescriberInReviewIndex, 1);
      }

      state.filtered.push({
        id: "clientNameAbbreviation",
        value: this.props.selectedClientNameAbbreviation,
        isdefault: true,
      });

      this.props.updateInterventionList(
        state.pageSize,
        state.page,
        state.sorted,
        state.filtered,
        state.resized,
        state.expanded
      );
    }
  };
  componentDidMount() {
    const { listState } = this.props;
    this.updateData(listState);
  }

  componentDidUpdate(prevProps) {
    const { moveToTheListView } = this.props;
    if (moveToTheListView !== prevProps.moveToTheListView) {
      if (this.tableRef && this.tableRef.current) {
        this.tableRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  toggleSelection = (selected) => {
    selected = Object.keys(selected).map((row, index) => {
      return row;
    });
    this.props.setSelectedSwitches(selected);
  };

  toggleAll = () => {
    let { data } = this.props;
    let selected = [...this.props.selected];
    let isAllSelected = this.selectAll();
    // select all
    data.forEach((item) => {
      const { wfItemId } = item;
      const index = selected.indexOf(wfItemId);
      if (isAllSelected) {
        //  remove object
        selected.splice(index, 1);
      } else if (index < 0) {
        // add object
        selected.push(wfItemId);
      }
    });
    this.props.setSelectedSwitches(selected);
  };

  selectAll = () => {
    const { data, selected } = this.props;
    let isAllSelected = true;
    data.forEach((x) => {
      if (selected.indexOf(x.wfItemId) < 0) {
        isAllSelected = false;
        return;
      }
    });
    return isAllSelected;
  };

  getSwitchesForSelectAll = (e) => {
    const { checked } = e.target;
    const { filtered, sorted } = this.props.listState;
    this.updateData(this.props.listState);
    if (checked) {
      sendAuthenticatedAsyncRequest(
        `/swim/get-switches-for-select-all`, // review this api call
        "POST",
        { filtered, sorted },
        (r) => {
          this.setState({ wfItemIds: r.data, toggleAllSwitches: checked });
          this.selectAllRecords(r.data, checked);
        }
      );
    } else {
      this.setState({ wfItemIds: [], toggleAllSwitches: checked });
      this.selectAllRecords([], checked);
    }
  };

  getSwitchesByToggle = (e) => {
    const { checked } = e.target;
    this.setState({ toggleDefaultFilters: checked });
    const { filtered } = this.props.listState;
    let updatedFiltered = [...filtered];
    if (e.target.checked) {
      updatedFiltered = updatedFiltered.filter(
        (element) => element.id !== "state" && element.id !== "status"
      );
      updatedFiltered.push(...SWIM_CYCLE_FILTER.default);
    } else {
      updatedFiltered = updatedFiltered.filter(
        (element) => element.id !== "state" && element.id !== "status"
      );
    }
    this.updateData({
      ...this.props.listState,
      filtered: updatedFiltered,
    });
  };

  selectAllRecords = (wfItemIds, checked) => {
    this.toggleAll();
    this.props.setSelectedSwitches(wfItemIds);
    this.setState({ isSelectAll: checked });
  };
  fetchBioSimilarSwitches = (e) => {
    const { filtered, sorted } = this.props.listState;
    let updatedFiltered = [...filtered];
    if (e.target.checked) {
      this.props.setBioSimilarSwitchesToggle(true);
      updatedFiltered = updatedFiltered.filter(
        (element) => element.id !== "priorityAgent"
      );
      updatedFiltered.push({
        id: "priorityAgent",
        value: true,
      });
    } else {
      this.props.setBioSimilarSwitchesToggle(false);
      updatedFiltered = updatedFiltered.filter(
        (element) => element.id !== "priorityAgent"
      );
      updatedFiltered.push({
        id: "priorityAgent",
        value: false,
      });
    }
    this.updateData({
      ...this.props.listState,
      filtered: updatedFiltered,
      sorted,
    });
  };

  render() {
    const { toggleAllSwitches, toggleDefaultFilters } = this.state;
    return (
      <Col md={{ span: 15 }} style={{ paddingTop: "10px" }}>
        <div
          ref={(el) => {
            this.tableRef = el;
          }}
        />
        <div style={{ paddingBottom: "10px" }}>
          <Card variant="primary">
            <Card.Body>
              <Row style={{ flexDirection: "column" }}>
                <Col>
                  {this.props.selectedClientNameAbbreviation.length > 0 &&
                    this.props.selectedClientNameAbbreviation[0] === "WMT" &&
                    isLoggedInUserBioSimilarUser() && (
                      <Form.Check
                        type="switch"
                        id="bioSimilarSwitchesToggle"
                        label="Bio Similar Switches"
                        onChange={this.fetchBioSimilarSwitches}
                        style={{
                          display: "inline",
                          paddingRight: "5px",
                          paddingBottom: "20px",
                        }}
                        checked={this.props.bioSimilarSwitchesToggle}
                      />
                    )}
                </Col>
                <Col>
                  <Form.Check
                    type="switch"
                    id="selectAll"
                    label="Select All Switches"
                    onChange={this.getSwitchesForSelectAll}
                    //value={isSelectAll}
                    style={{ display: "inline", paddingRight: "5px" }}
                    checked={toggleAllSwitches}
                  ></Form.Check>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        This will select all the switches from all pages
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <span ref={ref} {...triggerHandler}>
                        <MdHelp
                          style={{ color: "#FFAA3B", cursor: "pointer" }}
                        ></MdHelp>
                      </span>
                    )}
                  </OverlayTrigger>
                  <Form.Check
                    type="switch"
                    id="defaultFilters"
                    label="Default Filters"
                    onChange={this.getSwitchesByToggle}
                    style={{
                      display: "inline",
                      paddingRight: "5px",
                      marginLeft: "30px",
                    }}
                    checked={toggleDefaultFilters}
                  ></Form.Check>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        This will remove all the default filters
                      </Tooltip>
                    }
                  >
                    {({ ref, ...triggerHandler }) => (
                      <span ref={ref} {...triggerHandler}>
                        <MdHelp
                          style={{ color: "#FFAA3B", cursor: "pointer" }}
                        ></MdHelp>
                      </span>
                    )}
                  </OverlayTrigger>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
        <SwitchListCommon
          updateInterventionList={this.props.updateInterventionList}
          updateIndexOnList={this.props.updateIndexOnList}
          resetError={this.props.resetError}
          data={this.props.data}
          pages={this.props.pages}
          totalElements={this.props.totalElements}
          loading={this.props.loading}
          listState={this.props.listState}
          isError={this.props.isError}
          updateData={this.updateData}
          columnList={COLUMN_LIST}
          selectedClient={this.props.selectedClientNameAbbreviation}
          isSortFilterEnabledForCust={false}
          toggleSelection={this.toggleSelection}
          isSelectAll={this.state.isSelectAll}
          selected={this.props.selected}
          resetTableSelection={this.state.resetTableSelection}
          isViewable={false}
        />
      </Col>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let listState = state.switchCycleList.listState;
  if (!listState) {
    listState = {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      sorted: DEFAULT_SWIM_SORT,
      filtered: [
        ...SWIM_CYCLE_FILTER.default,
        { id: "priorityAgent", value: false },
      ],
      resized: [],
      expanded: {},
    };
    listState.filtered = [
      ...listState.filtered,
      {
        id: "clientNameAbbreviation",
        value: ownProps.selectedClientNameAbbreviation,
        isdefault: true,
      },
    ];
  }
  return {
    data:
      state.switchCycleList.data !== undefined
        ? state.switchCycleList.data
        : [],
    pages: state.switchCycleList.pages ? state.switchCycleList.pages : 0,
    totalElements:
      state.switchCycleList.totalElements !== undefined
        ? state.switchCycleList.totalElements
        : "Loading ...",
    loading: state.switchCycleList.loading,
    listState: listState,
    isError: state.switchCycleList.isError,
    wfItemIds: state.switchCycleList.wfItemIds
      ? state.switchCycleList.wfItemIds
      : [],
    isSelectAll: state.switchCycleList.isSelectAll,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInterventionList: bindActionCreators(
      updateInterventionList,
      dispatch
    ),
    updateIndexOnList: bindActionCreators(updateIndexOnList, dispatch),
    resetError: bindActionCreators(resetError, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SwitchCycleList));
