import Colors from "./Colors";
import { FaSmile, FaMeh, FaFrown } from 'react-icons/fa';

export const HOLD_TIME_LIMIT = "Hold Time Limit";
const SPOKE_TO_A_PERSON = "Spoke to a person";
const LEFT_VOICEMAIL = "Left Voicemail";
const NO_ANSWER = "No Answer";
const MOVE_TO_TASK_LIST = "Move To Task List";
const DATA_UPDATE_COMPLETED = "Data Update Completed";
const ESCALATE = "Escalate";
const DEESCALATE = "De-escalate";
const VIEWED_NO_ACTION_TAKEN = "Viewed/No Action Taken";
const PRESCRIBER_VERBAL_EHR = "Prescriber Verbal EHR";
const PRESCRIBER_DO_NOT_CONTACT_REQUEST = "Prescriber Do Not Contact Request";
const MEMBER_DO_NOT_CONTACT_REQUEST = "Member Do Not Contact Request";
const PRESCRIBER_VERBAL_DECLINE = "Prescriber Verbal Decline";
const MEMBER_VERBAL_DECLINE = "Member Verbal Decline";
const MEMBER_CALL = "Member Call";
const SCHEDULE_PRESCRIBER_CALL = "Schedule Prescriber Call";
const PHARMACY_FILL_AWAITING_PICKUP = "Pharmacy Fill Awaiting Pickup";
const PHARMACY_CONFIRMED_PICKUP = "Pharmacy Confirmed Pickup";
const MEMBER_CONFIRMED_PICKUP = "Member Confirmed Pickup";
const FAX_RESEND = "Fax Resend";
const PRESCRIBER_OPT_OUT = "Prescriber Opt Out";
const MEMBER_OPT_OUT = "Member Opt Out";
const GATEKEEPER = "Gatekeeper";
const FORMULARY_PLAN_ISSUE = "Formulary/Plan Issue";
const EMERGENCY_DOCTOR_HOSPITALIST = "Emergency Doctor / Hospitalist";
const PROVIDER_RETIRED = "Provider Retired";
const NO_FAX_MACHINE = "No Fax Machine";
const OTHER = "Other";

const PROVIDER_NOT_AT_PRACTICE = "Provider Not At Practice";
const NUMBER_NOT_IN_SERVICE = "Number Not In Service/Disconnect, Fax Beeping Tone";
const NO_ANSWER_AUTO_VM = "No Answer - Auto VM";
const NO_ANSWER_NON_STOP_RINGING = "No Answer - Non-Stop Ringing";
const PERSONAL_CELL_PHONE_NUMBER = "Personal Cell Phone Number";
const MISSING_PHONE_NUMBER = "Missing Phone Number";
const MISSING_FAX_NUMBER = "Missing Fax Number";
const FAX_NUMBER_NOT_PROVIDED_BY_PRESCRIBER = "Fax Number Not Provided By Prescriber";

const DELIVERED_PRESCRIBER_FAX = "DELIVERED_PRESCRIBER_FAX";
const ERROR_PRESCRIBER_FAX = "ERROR_PRESCRIBER_FAX";
const FAILED_PRESCRIBER_FAX = "FAILED_PRESCRIBER_FAX";
const DELIVERED_PHARMACY_FAX = "DELIVERED_PHARMACY_FAX";
const ERROR_PHARMACY_FAX = "ERROR_PHARMACY_FAX";
const FAILED_PHARMACY_FAX = "FAILED_PHARMACY_FAX";

const AW_ADMIN = "aw_admin";
const AW_CALL_ACTIVITY = "aw_call_activity";
const AW_TASK_ACTIVITY = "aw_task_activity";
const AW_MEM_CALLER = "aw_mem_caller";


const HAPPY = "Happy"
const NEUTRAL = "Neutral"
const SAD = "Sad"


export const AgentWorkspaceConstants = {
  stateAbbreviations: [
    { key: "Alabama", value: "AL" }, 
    { key: "Alaska", value: "AK" }, 
    { key: "Arizona", value: "AZ" }, 
    { key: "Arkansas", value: "AR" }, 
    { key: "Armed Forces Americas (Except Canada)", value: "AA"}, 
    { key: "Armed Forces Europe, the Middle East, and Canada", value: "AE" }, 
    { key: "Armed Forces Pacific", value: "AP" }, 
    { key: "California", value: "CA" }, 
    { key: "Colorado", value: "CO" }, 
    { key: "Connecticut", value: "CT" },
    { key: "Delaware", value: "DE" },
    { key: "District of Columbia", value: "DC" },
    { key: "Florida", value: "FL" },
    { key: "Georgia", value: "GA" },
    { key: "Guam", value: "GU" },
    { key: "Hawaii", value: "HI" },
    { key: "Idaho", value: "ID" },
    { key: "Illinois", value: "IL" },
    { key: "Indiana", value: "IN" },
    { key: "Iowa", value: "IA" },
    { key: "Kansas", value: "KS" },
    { key: "Kentucky", value: "KY" },
    { key: "Louisiana", value: "LA" },
    { key: "Maine", value: "ME" },
    { key: "Maryland", value: "MD" },
    { key: "Massachusetts", value: "MA" },
    { key: "Michigan", value: "MI" },
    { key: "Minnesota", value: "MN" },
    { key: "Mississippi", value: "MS" },
    { key: "Missouri", value: "MO" },
    { key: "Montana", value: "MT" },
    { key: "Nebraska", value: "NE" },
    { key: "Nevada", value: "NV" },
    { key: "New Hampshire", value: "NH" },
    { key: "New Jersey", value: "NJ" },
    { key: "New Mexico", value: "NM" },
    { key: "New York", value: "NY" },
    { key: "North Carolina", value: "NC" },
    { key: "North Dakota", value: "ND" },
    { key: "Northern Mariana Islands", value: "MP" },
    { key: "Ohio", value: "OH" },
    { key: "Oklahoma", value: "OK" },
    { key: "Oregon", value: "OR" },
    { key: "Pennsylvania", value: "PA" },
    { key: "Puerto Rico", value: "PR" },
    { key: "Rhode Island", value: "RI" },
    { key: "South Carolina", value: "SC" },
    { key: "South Dakota", value: "SD" },
    { key: "Tennessee", value: "TN" },
    { key: "Texas", value: "TX" },
    { key: "Trust Territories", value: "TT" },
    { key: "Utah", value: "UT" },
    { key: "Vermont", value: "VT" },
    { key: "Virginia", value: "VA" },
    { key: "Virgin Islands", value: "VI" },
    { key: "Washington", value: "WA" },
    { key: "West Virginia", value: "WV" },
    { key: "Wisconsin", value: "WI" },
    { key: "Wyoming", value: "WY" },
  ],
  
  callGroupTypes: {
    admin: "ADMIN",
    call: "CALL",
    task: "TASK",
    clinical: "CLINICAL",
  },
  prescriberFaxResendableStates: [
    DELIVERED_PRESCRIBER_FAX,
    ERROR_PRESCRIBER_FAX,
    FAILED_PRESCRIBER_FAX,
  ],
  pharmacyFaxResendableStates: [
    DELIVERED_PHARMACY_FAX,
    ERROR_PHARMACY_FAX,
    FAILED_PHARMACY_FAX,
  ],
  statusColors: {
    Open: Colors["rm-blue"],
    Closed: Colors["rm-danger"],
  },
  roles: [AW_ADMIN, AW_CALL_ACTIVITY, AW_TASK_ACTIVITY, AW_MEM_CALLER],
  activityClosingReasons: [
    HOLD_TIME_LIMIT,
    SPOKE_TO_A_PERSON,
    LEFT_VOICEMAIL,
    NO_ANSWER,
    MOVE_TO_TASK_LIST,
    DATA_UPDATE_COMPLETED,
    ESCALATE,
    FAX_RESEND,
    VIEWED_NO_ACTION_TAKEN,
    MEMBER_CALL,
    SCHEDULE_PRESCRIBER_CALL,
    PRESCRIBER_DO_NOT_CONTACT_REQUEST,
    PHARMACY_FILL_AWAITING_PICKUP,
    PHARMACY_CONFIRMED_PICKUP,
    GATEKEEPER,
    OTHER,
  ],
  closeReasonsForCallMemberOnly: [
    MEMBER_CONFIRMED_PICKUP
  ],
  closeReasonsForAdminOnly: [
    DEESCALATE
  ],
  closeReasonsForRescheduling: [ // mandatory rescheduling
    SPOKE_TO_A_PERSON,
    LEFT_VOICEMAIL,
    NO_ANSWER,
    FAX_RESEND,
    OTHER,
  ],
  closeReasonsForReschedulingFieldDisplay: [ // optional rescheduling
    SPOKE_TO_A_PERSON,
    LEFT_VOICEMAIL,
    NO_ANSWER,
    FAX_RESEND,
    OTHER,
  ],
  switchDispositionsForChangeDisposition: [
    PRESCRIBER_VERBAL_EHR, // submitted via ehr
    MEMBER_DO_NOT_CONTACT_REQUEST, // to decline
    PRESCRIBER_VERBAL_DECLINE, // to decline
    MEMBER_VERBAL_DECLINE, // to decline
    EMERGENCY_DOCTOR_HOSPITALIST,
    PROVIDER_RETIRED,
    NO_FAX_MACHINE
  ],
  closeReasonsForCloseConfirmation: [
    FAX_RESEND,
    GATEKEEPER
  ],
  closeReasonsForClosingCallActivity: [
    MOVE_TO_TASK_LIST
  ],
  switchDispositionsForCloseConfirmation: [
    PRESCRIBER_VERBAL_EHR, // submitted via ehr
    MEMBER_DO_NOT_CONTACT_REQUEST, // to decline
    PRESCRIBER_VERBAL_DECLINE, // to decline
    MEMBER_VERBAL_DECLINE, // to decline
    PRESCRIBER_OPT_OUT,
    MEMBER_OPT_OUT,
    EMERGENCY_DOCTOR_HOSPITALIST,
    PROVIDER_RETIRED,
    NO_FAX_MACHINE
  ],
  closeReasonsForGroupSaveOption: [
    HOLD_TIME_LIMIT,
    MOVE_TO_TASK_LIST,
    DATA_UPDATE_COMPLETED,
    ESCALATE,
    DEESCALATE,
    MEMBER_CALL,
    LEFT_VOICEMAIL,
    NO_ANSWER,
    VIEWED_NO_ACTION_TAKEN,
    SPOKE_TO_A_PERSON,
    FAX_RESEND,
    OTHER
  ],
  switchDispositionsForGroupSaveOption: [
    MEMBER_DO_NOT_CONTACT_REQUEST,
    FORMULARY_PLAN_ISSUE,
    EMERGENCY_DOCTOR_HOSPITALIST,
    PROVIDER_RETIRED,
    NO_FAX_MACHINE
  ],
  closeReasonsWithMandatoryNotes: [
    HOLD_TIME_LIMIT,
    MOVE_TO_TASK_LIST,
    DATA_UPDATE_COMPLETED,
    DEESCALATE,
    ESCALATE,
    FAX_RESEND,
    MEMBER_CALL,
    MEMBER_CONFIRMED_PICKUP,
    NO_ANSWER,
    OTHER,
    SCHEDULE_PRESCRIBER_CALL,
    PRESCRIBER_DO_NOT_CONTACT_REQUEST,
    SPOKE_TO_A_PERSON,
    GATEKEEPER
  ],
  switchDispositionsWithMandatoryNotes: [
    MEMBER_DO_NOT_CONTACT_REQUEST,
    MEMBER_OPT_OUT,
    MEMBER_VERBAL_DECLINE,
    FORMULARY_PLAN_ISSUE,
    PRESCRIBER_OPT_OUT,
    PRESCRIBER_VERBAL_DECLINE,
    PRESCRIBER_VERBAL_EHR,
    EMERGENCY_DOCTOR_HOSPITALIST,
    PROVIDER_RETIRED,
    NO_FAX_MACHINE
  ],
  closeReasonsWithMandatoryNotesForEscalated: [
    LEFT_VOICEMAIL,
    NO_ANSWER,
  ],
  switchDispositionsForDeclinedDisposition: [
    MEMBER_DO_NOT_CONTACT_REQUEST,
    PRESCRIBER_VERBAL_DECLINE,
    MEMBER_VERBAL_DECLINE,
  ],
  closeReasonsThatClosesActivity: [
    MOVE_TO_TASK_LIST,
    DATA_UPDATE_COMPLETED,
    ESCALATE,
    DEESCALATE,
    PRESCRIBER_DO_NOT_CONTACT_REQUEST,
    PHARMACY_CONFIRMED_PICKUP,
    MEMBER_CONFIRMED_PICKUP,
    GATEKEEPER
  ],
  switchDispositionsThatClosesActivity: [
    PRESCRIBER_VERBAL_EHR,
    MEMBER_DO_NOT_CONTACT_REQUEST,
    PRESCRIBER_VERBAL_DECLINE,
    MEMBER_VERBAL_DECLINE,
    EMERGENCY_DOCTOR_HOSPITALIST,
    PROVIDER_RETIRED,
    NO_FAX_MACHINE
  ],
  closeReasonsToExcludeForAssignAgent: [
    HOLD_TIME_LIMIT,
    VIEWED_NO_ACTION_TAKEN,
    MOVE_TO_TASK_LIST,
    DATA_UPDATE_COMPLETED,
    MEMBER_CONFIRMED_PICKUP,
    ESCALATE,
    PRESCRIBER_DO_NOT_CONTACT_REQUEST,
    PHARMACY_CONFIRMED_PICKUP,
    DEESCALATE
  ],
  switchDispositionsToExcludeForAssignAgent: [
    PRESCRIBER_VERBAL_EHR,
    MEMBER_DO_NOT_CONTACT_REQUEST,
    PRESCRIBER_VERBAL_DECLINE,
    MEMBER_VERBAL_DECLINE,
    PRESCRIBER_OPT_OUT,
    MEMBER_OPT_OUT,
    GATEKEEPER,
    EMERGENCY_DOCTOR_HOSPITALIST,
    PROVIDER_RETIRED,
    NO_FAX_MACHINE
  ],
  closeReasonsForStateMapping: {
    "Member Call": "Member Call",
    "Pharmacy Fill Awaiting Pickup": "Pharmacy - Fill Awaiting Pick up",
    "Pharmacy Confirmed Pickup": "Pharmacy - Confirmed Pick up",
  },
  closeReasonsForEnablingState: [
    SPOKE_TO_A_PERSON,
    OTHER
  ],
  closeReasonsForEnablingSwitchDisposition: [ //Also add entry in BE AW-Constants
    DEESCALATE,
    SPOKE_TO_A_PERSON,
    OTHER
  ],
  closeReasonsForDisplayingCallTemperature : [
    HOLD_TIME_LIMIT,
    SPOKE_TO_A_PERSON,
    MOVE_TO_TASK_LIST,
    DATA_UPDATE_COMPLETED,
    ESCALATE,
    DEESCALATE,
    FAX_RESEND,
    MEMBER_CALL,
    SCHEDULE_PRESCRIBER_CALL,
    PRESCRIBER_DO_NOT_CONTACT_REQUEST,
    PHARMACY_FILL_AWAITING_PICKUP,
    PHARMACY_CONFIRMED_PICKUP,
    GATEKEEPER,
    OTHER
  ],
  TASK_REASONS_LIST: [
    PROVIDER_NOT_AT_PRACTICE,
    NUMBER_NOT_IN_SERVICE,
    NO_ANSWER_AUTO_VM,
    NO_ANSWER_NON_STOP_RINGING,
    PERSONAL_CELL_PHONE_NUMBER,
    MISSING_PHONE_NUMBER,
    MISSING_FAX_NUMBER,
    FAX_NUMBER_NOT_PROVIDED_BY_PRESCRIBER
  ],
  SWITCH_DISPOSITIONS_LIST: [
    PRESCRIBER_VERBAL_EHR,
    MEMBER_DO_NOT_CONTACT_REQUEST,
    PRESCRIBER_VERBAL_DECLINE,
    MEMBER_VERBAL_DECLINE,
    PRESCRIBER_OPT_OUT,
    MEMBER_OPT_OUT, 
    FORMULARY_PLAN_ISSUE,
    EMERGENCY_DOCTOR_HOSPITALIST,
    PROVIDER_RETIRED,
    NO_FAX_MACHINE
  ],
  callGroupTemperature : [
    { id: HAPPY , icon: FaSmile, color: 'green', value: 3 },  
    { id: NEUTRAL, icon: FaMeh, color: '#FF8C00', value: 2  }, 
    { id: SAD, icon: FaFrown, color: 'red', value: 1 }
  ],
  activityStatusFilter: ["ALL", "NEW", "OPEN", "CLOSED", "ON_HOLD", "ESCALATED"],
  activityStatusMultiSelectOptions: [{ id: "NEW", name: "New" }, { id: "OPEN", name: "Open" }, { id: "CLOSED", name: "Closed" }
    , { id: "ON_HOLD", name: "On Hold" }, { id: "ESCALATED", name: "Escalated" }],
  activityStatusOpenFilter: ["NEW", "OPEN", "ON_HOLD", "ESCALATED"],
  callGroupStatusFilter: ["OPEN", "CLOSED", "ON_HOLD"],
  activityTypeFilter: ["PHONE_TASK", "FAX_TASK", "CALL", "FAX_FAIL"],
  basicEntityTypeFilter: ["PRESCRIBER", "PHARMACY"],
  entityTypeFilter: ["PRESCRIBER", "PHARMACY", "MEMBER"],
  DEFAULT_FILTER: [{ id: "status", value: "OPEN", isDefault: true }],
  DEFAULT_SORT: { id: "priority", desc: true, isDefault: true },
  DEFAULT_PAGE_SIZE: 15,
  DEFAULT_PAGE_SIZE_OPTIONS: [5, 10, 15, 20, 30, 50],
  SCHEDULE_DATE_WITHIN_DAYS: 1,
  CUSTOMER_CLIENT_FILTER_KEYS: [
    "customerNameAbbreviation",
    "clientNameAbbreviation",
  ],
  PRESCRIBER_ACTIVITY_STATES: [
    { label: "Prescriber - Fax Received", value: "Prescriber - Fax Received" },
    {
      label: "Prescriber - Fax In Review",
      value: "Prescriber - Fax In Review",
    },
  ],
  PHARMACY_ACTIVITY_STATES: [
    { label: "Pharmacy - Fill Pending", value: "Pharmacy - Fill Pending" },
    {
      label: "Pharmacy - Fill Awaiting Pick up",
      value: "Pharmacy - Fill Awaiting Pick up",
    },
    {
      label: "Pharmacy - Confirmed Pick up",
      value: "Pharmacy - Confirmed Pick up",
    },
  ],
  TRANSCRIBER_FIELDS: { text: "text", list: "list", check: "check" },
  TRANSCRIBER_OBJECT_KEYS: {
    selected: "selected",
    pages: "pages",
    pageNo: "pageNo",
    sections: "sections",
    sectionName: "sectionName",
    fields: "fields",
    fieldName: "fieldName",
  },
  ACTIVITY_STATE_FOR_CLOSING: "Pharmacy - Confirmed Pick up",
  CallCounterLimit: 3,
  MainFaxNumber: "512-501-1054",
  AlternateFaxNumber: "866-811-0267",
  PREDECESSOR: "PREDECESSOR",
  SUCCESSOR: "SUCCESSOR",
  MANUALLY_CREATED: "Manually Created",
  UNLINKED: "Unlinked",
  SWIM_SWITCH: "Swim Switch",
  PinnedNotesLimit: 3,
  CALL_PRIORTIZATION_SYSTEM: "by Call Priortization System",
  IN_PROGRESS_STATUSES: ["OPEN", "NEW", "ESCALATED"],
  MULTIPLE_ACTIVTIES: "Multiple Activities",
  LINKED_ACTIVITY: "LINKED_ACTIVITY",
  CALL_GROUP_LIST_MULTI_FILTER_IDS: ['customerNameAbbreviation', 'clientNameAbbreviation', 'status', 'type', 'entityType', 'timeZone'],
};

export const fetchActivityTypesForEntityType = (entityType) => {
  if (entityType === "MEMBER") {
    return ["CALL"];
  } else {
    return AgentWorkspaceConstants.activityTypeFilter;
  }
};

export const ActivityEvents = {
  ESCALATE: "ESCALATE",
  ADDED_TO_AW: "ADDED_TO_AW",
  HOLD: "HOLD",
  DEESCALATE: "DEESCALATE",
  EXTERNAL_CHANGE: "WF_STATE_CHANGE/MANUAL_CREATION",
  REOPEN: "REOPEN",
  CLOSE: "CLOSE",
  UPDATE_STATE: "UPDATE_STATE",
  RESCHEDULE: "RESCHEDULE",
  FAX_UNLINKED: "FAX_UNLINKED"
};

export const closeReasonDisplayEnabledForEvents = (activityEvent) => {
  return activityEvent == ActivityEvents.CLOSE || activityEvent == ActivityEvents.RESCHEDULE;
};
