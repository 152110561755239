import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSwitchDetails, getSwitchTimeline } from "./SwitchDetails.actions";
import { withRouter } from "react-router-dom";
import {
  resetSubmittedDisposition,
  resetEventRequest,
} from "views/Raw/SwitchDisposition/SwitchDisposition.actions";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import cloneDeep from "lodash/cloneDeep";

import "./SwitchDetails.css";
import "assets/css/ribbon.css";
import SecurePdfViewer from "components/SecurePdfViewer";
import {
  SWIM_HISTORY_STATE,
  SWIM_STATE,
  NOT_STARTED_STATUS_WITH_HISTORY_OF_CHUNK,
} from "lookup/SwimConstants";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import DismissableModal from "components/DismissableModal";
import EntityDetails from "components/SwitchDetails/EntityDetails";
import ProjectedSavings from "components/SwitchDetails/ProjectedSavings";
import OriginalClaim from "components/SwitchDetails/OriginalClaim";
import CustomerAndClientTitle from "components/CustomerAndClientTitle";
import MedicineHistory from "components/SwitchDetails/MedicineHistory";
import SwitchStateChanger from "components/SwitchDetails/SwitchStateChanger/SwitchStateChanger";
import Notes from "components/SwitchDetails/Notes";
import SwitchTimelineInfo from "components/SwitchDetails/SwitchTimelineInfo";
import SwitchTimeline from "components/SwitchDetails/SwitchTimeline";
import { Button } from "react-bootstrap";
import { WorkflowConstants } from "lookup/WorkflowConstants";
import { NotesFilterConstants } from "lookup/NotesFilterConstants";
import BatchOrCycle from "components/BatchOrCycle";
import CloseSwitchModal from "components/SwitchDetails/CloseSwitchModal";
import { getErrorMessage } from "services/UtilityService";
import CreateActivity from "components/SwitchDetails/CreateActivity";
import { showNotification } from "services/NotificationService";
import ChangeSwitchTemplate from "components/SwitchDetails/ChangeSwitchTemplate";

export const prepareTimelineForDisplay = (timeline) => {
  // remove the history chunk from the main timeline, and place history of all the other chucks in there respective timeline.
  //keep the formate of the induvaul history chuhn same as the outer chunk

  if (timeline && timeline.length > 1) {
    //in case of onhold state only single chuck in timeline is avaible which should be displated as it is,
    // for ever other case it has contant value of 6

    const history = timeline.filter(
      (element) => element.title.toUpperCase() === SWIM_HISTORY_STATE
    );
    const historyEvents = history.length > 0 ? history[0].events : [];

    if (historyEvents && historyEvents.length > 0) {
      //create a map of history of all chunks
      let map = new Map();
      historyEvents.forEach((element) => {
        if (map.has(element.uiState.toUpperCase())) {
          map.get(element.uiState.toUpperCase()).push(element);
        } else map.set(element.uiState.toUpperCase(), [element]);
      });

      const newTimeline = timeline.filter(
        (element) => !(element.title.toUpperCase() === SWIM_HISTORY_STATE)
      );

      // for each chunk which was active before going to ON_HOLD state, place the history object in it which has same
      // title and as History chunk but the events are according to chunk history

      newTimeline.forEach((element, index) => {
        if (element.title) {
          if (map.has(element.title.toUpperCase())) {
            const chunkHistory = map.get(element.title.toUpperCase());
            let chunkEventHistory = cloneDeep(history[0]);
            chunkEventHistory.events = cloneDeep(chunkHistory);
            element.history = cloneDeep(chunkEventHistory);
            // if the current chunk is inative and has a history, save its stae as acient to show it as a history chuk
            if (element.state === SWIM_STATE.InActive) {
              element.state = SWIM_STATE.History;
              element.lastEvent = {
                status: NOT_STARTED_STATUS_WITH_HISTORY_OF_CHUNK,
                date: chunkEventHistory.lastEvent.date,
              };
            }
          }
        }
      });

      return newTimeline;
    }
  }
  return timeline;
};

class SwitchDetails extends React.Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
  }

  state = {
    switchData: null,
    loading: true,
    redactedFax: undefined,
    customerId: null,
    switchId: null,
    switchTimeline: null,
    timelineLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      switchData: nextProps.switchData,
      loading: nextProps.loading,
      switchTimeline: nextProps.switchTimeline,
      timelineLoading: nextProps.timelineLoading,
    });

    if (!nextProps.loading && nextProps.switchData) {
      this.setState({
        customerId: nextProps.switchData.switchInfo.rirCustomerInfo.customerId,
        switchId: nextProps.switchData.switchInfo.rirSwitchId,
      });
    }

    if (nextProps.showDispositionSuccessNotification) {
      showNotification({
        title: "Successfully updated Switch Disposition!",
        message: "The switch disposition has been updated for this switch.",
        position: "bl",
        type: "success",
      });
      this.props.resetSubmittedDisposition();
    }

    if (nextProps.showEventSuccessNotification) {
      showNotification({
        title: "Event Executed!",
        message: "The event has been executed on RAW.",
        position: "bl",
        type: "success",
      });
      this.props.resetEventRequest();
    }
  }

  showErrorNotification = (title, responseData) => {
    showNotification({
      title: title,
      message:
        getErrorMessage(responseData) ||
        getErrorMessage("SOMETHING_WENT_WRONG"),
      position: "tr",
      type: "error",
    });
  };

  fetchData() {
    const wfItemId = this.currentWfItemIdFromPath();
    this.props.getSwitchTimeline(wfItemId);
    this.props.getSwitchDetails(wfItemId);
  }

  fetchRedactedFax(rirSwitchInfo) {
    this.setState({ redactedFax: undefined });
    sendAuthenticatedAsyncRequest(
      `/pdf/${rirSwitchInfo.rirCustomerInfo.customerId}/prescriber-inbound-or-generated-redacted?switch_id=${rirSwitchInfo.rirSwitchId}`,
      "GET",
      null,
      (r) => this.setState({ redactedFax: "data:pdf;base64," + r.data }),
      (r) => this.setState({ redactedFax: null })
    );
  }

  currentWfItemIdFromPath = () => this.props.match.params.wfItemId;

  redirectToLinkFaxDetailsPage(closeModal, faxInfoIdentifier) {
    closeModal();
    this.props.history.push(
      `/unprocessed-fax/fax-details/${faxInfoIdentifier}`
    );
  }
  handleButtonClicks(switchWfItemId) {
    this.props.history.push(`/switch/${switchWfItemId}`);
    this.props.getSwitchTimeline(switchWfItemId);
    this.props.getSwitchDetails(switchWfItemId);
  }

  render() {
    const {
      switchData,
      loading,
      customerId,
      switchId,
      switchTimeline,
      timelineLoading,
    } = this.state;
    if (loading || timelineLoading) {
      return "Loading ...";
    } else if (
      (!loading && !switchData) ||
      (!timelineLoading && !switchTimeline)
    ) {
      return "Error while fetching switch data";
    }
    const switchInfo = switchData.switchInfo;
    const isPrescriberResponseFaxAvailable =
      switchData.isPrescriberResponseFaxAvailable;
    const timeline = prepareTimelineForDisplay(switchTimeline.timeline);
    const workflowRestarted = timeline[0].history ? true : false;
    const securePdfModalRef = "redactedPdfViewerModal";
    const fillDetails = switchData.fillDetails;
    const faxInfoIdentifier = switchInfo.faxInfoIdentifier || "";
    const customFooterContent = (
      <div>
        <Button
          id="redacted-pdf-secure-viewer-close"
          variant="outline-dark"
          onClick={() => this.refs[securePdfModalRef].handleClose()}
        >
          Close
        </Button>
        {faxInfoIdentifier !== "" ? (
          <Button
            id="redacted-pdf-secure-viewer-redirect-to-linking"
            className="left-spacer"
            variant="success"
            onClick={() =>
              this.redirectToLinkFaxDetailsPage(
                this.refs[securePdfModalRef].handleClose,
                faxInfoIdentifier
              )
            }
          >
            Link Fax Page(s)
          </Button>
        ) : null}
      </div>
    );
    return (
      <Container
        className={`bottom-spacer ${
          switchData.status === "ON_HOLD" ? "held-switch ribbon-container" : ""
        }`}
        fluid
      >
        {switchData.status === "ON_HOLD" ? (
          <div className="ribbon ribbon-red ribbon-top-left">
            <span>on hold</span>
          </div>
        ) : null}
        <Row>
          <Col md={6}>
            <SwitchTimelineInfo
              state={switchData.state}
              status={switchData.status}
              wfItemId={switchData.wfItemId}
              workflowRestarted={workflowRestarted}
            />
            <SwitchTimeline
              rawId={switchData.rawId}
              wfItemId={this.currentWfItemIdFromPath()}
              switchHistory={timeline}
              isPrescriberResponseFaxAvailable={
                isPrescriberResponseFaxAvailable
              }
              fillDetails={fillDetails}
              switchId={switchInfo.rirSwitchId}
              switchStatus={switchData.status}
              switchState={switchData.state}
              onStateChange={() => this.fetchData()}
            />
            <Row className="bottom-spacer">
              <Col md={6}>
                <CloseSwitchModal
                  wfItemId={this.currentWfItemIdFromPath()}
                  closestChainSwitchId={switchData.closestChainSwitchGlobalId}
                  isActionCallable={
                    switchData.status !==
                      WorkflowConstants.MISSING_PRESCRIBER_CONTACT_INFO &&
                    switchData.status !==
                      WorkflowConstants.DELIVERED_PRESCRIBER_FAX &&
                    switchData.status !==
                      "ERROR_PUTTING_ON_NO_PRESCRIBER_RESPONSE" &&
                    switchData.status !==
                      "ERROR_PUTTING_ON_PRESCRIBER_CONTACT" &&
                    switchData.status !== "GENERATED_PDF" &&
                    switchData.status !== "ON_HOLD"
                  }
                  onEventExecuted={() => this.fetchData()}
                />
              </Col>
              <Col md={2}>
                <SwitchStateChanger
                  wfItemId={this.currentWfItemIdFromPath()}
                  switchId={switchInfo.rirSwitchId}
                  event={switchData.status === "ON_HOLD" ? "RESUME" : "HOLD"}
                  onEventExecuted={() => this.fetchData()}
                />
              </Col>
              <Col md={4}>
                <SwitchStateChanger
                  wfItemId={this.currentWfItemIdFromPath()}
                  switchId={switchInfo.rirSwitchId}
                  event="GENERATE_PDF"
                  onEventExecuted={() => this.fetchData()}
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <div className="d-flex justify-content-between">
              <Button
                size="sm"
                className="transition-btn"
                onClick={() =>
                  this.handleButtonClicks(switchData.predecessorWfItemId)
                }
                disabled={!switchData.predecessorWfItemId}
              >
                Predecessor
              </Button>
              <CustomerAndClientTitle
                globalSwitchId={switchInfo.globalSwitchId}
                customerName={
                  switchInfo.rirCustomerInfo
                    ? switchInfo.rirCustomerInfo.customerName
                    : null
                }
                clientName={
                  switchInfo.clientInfo
                    ? switchInfo.clientInfo.clientName
                    : null
                }
                customerDisplayId={
                  switchInfo.rirCustomerInfo
                    ? switchInfo.rirCustomerInfo.customerDisplayId
                    : null
                }
                clientDisplayId={
                  switchInfo.clientInfo
                    ? switchInfo.clientInfo.clientDisplayId
                    : null
                }
                creationDate={switchInfo.creationDate}
                targetSwitchDate={switchInfo.targetSwitchDate}
                isCallActivityCreationBlocked={
                  switchData.isCallActivityCreationBlocked
                }
              />
              <Button
                size="sm"
                className="transition-btn"
                onClick={() =>
                  this.handleButtonClicks(switchData.successorWfItemId)
                }
                disabled={!switchData.successorWfItemId}
              >
                Successor
              </Button>
            </div>
            <Row className="top-spacer">
              <Col>
                {switchData.batch ? (
                  <BatchOrCycle
                    type="Batch"
                    data={switchData.batch}
                    id={switchData.batch.wfBatchId}
                  />
                ) : (
                  <Badge bg="warning">No batch for Switch</Badge>
                )}
              </Col>
              <Col>
                {switchData.cycle ? (
                  <BatchOrCycle
                    type="Cycle"
                    data={switchData.cycle}
                    id={switchData.cycle.wfCycleId}
                  />
                ) : (
                  <Badge bg="warning">No cycle for Switch</Badge>
                )}
              </Col>
            </Row>
            <Row className="top-spacer">
              <Col md={12}>
                <Notes
                  notes={switchData.notes}
                  entityId={switchData.wfItemId}
                  entitySaveUrl={"/wf/note"}
                  filtersList={NotesFilterConstants.generalNotesFilter}
                  errorNotification={this.showErrorNotification.bind(this)}
                />
              </Col>
            </Row>

            <Row className="top-spacer">
              <Col md={7}>
                <EntityDetails
                  prescriber={
                    switchInfo.prescriberInfo ? switchInfo.prescriberInfo : {}
                  }
                  pharmacy={switchInfo.pharmaInfo ? switchInfo.pharmaInfo : {}}
                  patient={
                    switchInfo.rirMemberInfo ? switchInfo.rirMemberInfo : {}
                  }
                  globalSwitchId={
                    switchInfo.globalSwitchId ? switchInfo.globalSwitchId : null
                  }
                  refreshSwim={this.fetchData.bind(this)}
                  wfItemId={this.currentWfItemIdFromPath()}
                />
              </Col>
              <Col md={5} className="top-spacer">
                <ProjectedSavings
                  paid={switchInfo.currentCost}
                  projected={switchInfo.projectedCost}
                  savings={switchInfo.projectedSavings}
                />
              </Col>
            </Row>
            <Row className="top-spacer">
              <Col md={3} className="d-grid gap-2">
                <CreateActivity
                  wfItemId={this.currentWfItemIdFromPath()}
                  isMemberActivityCreatable={
                    switchData.isMemberActivityCreatable
                  }
                  disableButton={false}
                  fetchData={this.fetchData}
                  selectedPrescriberUuids={
                    [switchData.switchInfo.prescriberInfo.prescriberInfoUuid] ??
                    []
                  }
                  selectedPrescriberNames={
                    [switchData.switchInfo.prescriberInfo.prescriberName] ?? []
                  }
                />
              </Col>
              <Col md={3} className="d-grid gap-2">
                <OriginalClaim switchInfo={switchInfo} />
              </Col>
              <Col md={3} className="d-grid gap-2">
                <MedicineHistory switchId={switchInfo.rirSwitchId} />
              </Col>
              <Col md={3} className="d-grid gap-2">
                <DismissableModal
                  ref={securePdfModalRef}
                  customFooterContent={customFooterContent}
                  openModalButtonText="Redacted Pdf"
                  openModalButtonBlock={true}
                  openModalButtonStyle="primary"
                  title="Redacted Pdf"
                  size="lg"
                >
                  {
                    <SecurePdfViewer
                      displayToolBar={false}
                      url={`/pdf/${customerId}/prescriber-inbound-or-generated-redacted?switch_id=${switchId}`}
                    />
                  }
                </DismissableModal>
              </Col>
            </Row>
            <Row className="tiny-top-spacer">
              <Col md={3} className="d-grid gap-2">
                <ChangeSwitchTemplate
                  rirSwitchId={switchInfo.rirSwitchId}
                  selectedTemplate={switchInfo.templateIndicator}
                  disableButton={false}
                  fetchData={this.fetchData}
                  showBioSimilarOptions={
                    switchData?.priorityAgent
                      ? switchData.priorityAgent
                      : false
                  }
                  showDrugDisruptionOptions={
                    switchData?.drugDisruption
                      ? switchData.drugDisruption
                      : false
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    switchData: state.switchDetails.switchData,
    loading: state.switchDetails.loading,
    switchTimeline: state.switchDetails.switchTimeline,
    timelineLoading: state.switchDetails.timelineLoading,
    showDispositionSuccessNotification:
      state.switchDisposition.submittedDisposition,
    showEventSuccessNotification: state.switchDisposition.eventRequestCompleted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSwitchDetails: bindActionCreators(getSwitchDetails, dispatch),
    getSwitchTimeline: bindActionCreators(getSwitchTimeline, dispatch),
    resetSubmittedDisposition: bindActionCreators(
      resetSubmittedDisposition,
      dispatch
    ),
    resetEventRequest: bindActionCreators(resetEventRequest, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SwitchDetails));
