import { withRouter } from "react-router-dom";
import UpdatePharmacyInfo from "components/UpdatePharmacyInfo";
import SwitchPharmacyInfo from "components/SwitchPharmacyInfo";
import { formatPhoneNumber, prependCountryCodeToPhoneNumber } from "services/UtilityService";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import { getLoggedInUser } from "services/AuthService";
import { isLoggedInUserAWSConnectAgent } from "services/AWUtilityService";
import ConnectCallButton from "components/CallCenterOption/connectCallButton";

const PharmacyDetails = (props) => {
  const [pharmacy, setPharmacy] = useState(props.pharmacy);
  const { isDisabled, wfItemId, globalSwitchId } = props;

  const updatePharmacyData = (updatedPharmacy) => {
    setPharmacy(updatedPharmacy);
  };
  const isAWSConnectAgentEnable = isLoggedInUserAWSConnectAgent();


  return (
    <>
      <div data-testid='text-pharmacy-name'>
        {pharmacy.pharmacyName} &nbsp;
        { pharmacy.gatekeeper ?
          <Badge bg="warning" pill data-testid='badge-pharmacy-gatekeeper'>
            Gatekeeper
          </Badge>
          :
          <></>
        }
      </div>
      
      {!isDisabled ? (
        <UpdatePharmacyInfo
          pharmacy={pharmacy}
          isDisabled={isDisabled}
          onSubmission={updatePharmacyData}
          updateData={props.updateData}
          wfItemId={wfItemId}
          isFromAW={props.isFromAW}
        />
      ) : null}
      {!isDisabled ? (
        <SwitchPharmacyInfo
          pharmacy={pharmacy}
          isDisabled={isDisabled}
          wfItemId={wfItemId}
          onSubmission={updatePharmacyData}
        />
      ) : null}
      <Row>
        <Col md={8}>
        <div className="rm-tiny-top-label tiny-top-spacer">Phone
        {isAWSConnectAgentEnable && <ConnectCallButton body={{ agent: getLoggedInUser()?.email, phone: prependCountryCodeToPhoneNumber(pharmacy?.pharmacyPhone, 1), switchId: globalSwitchId ,entityName:pharmacy.pharmacyName , entityType: 'Pharmacy'}} label='Pharmacy'/>}    
        </div>
        <div data-testid='text-pharmacy-phone'>{formatPhoneNumber(pharmacy.pharmacyPhone)}</div>
        </Col>
        <Col md={4}>
        <div className="rm-tiny-top-label tiny-top-spacer">Time Zone</div>
        <div data-testid='text-pharmacy-timezone'>{pharmacy.timeZone ? pharmacy.timeZone : "N/A"}</div>
        </Col>
      </Row>
      <div className="rm-tiny-top-label tiny-top-spacer">Fax</div>
      <div data-testid='text-pharmacy-fax'>{formatPhoneNumber(pharmacy.pharmacyFax)}</div>
      <div className="rm-tiny-top-label tiny-top-spacer">Npi</div>
      <div data-testid='text-pharmacy-npi'>{pharmacy.pharmacyNpi}</div>
      <div className="rm-tiny-top-label tiny-top-spacer">Address</div>
      <div data-testid='text-pharmacy-address'>{pharmacy.pharmacyAddress ? pharmacy.pharmacyAddress : "N/A"}</div>
    </>
  );
};

export default withRouter(PharmacyDetails);
