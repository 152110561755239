import { withRouter } from "react-router-dom";
import UpdatePrescriberInfo from "components/UpdatePrescriberInfo";
import SwitchPrescriberInfo from "components/SwitchPrescriberInfo";
import { formatPhoneNumber, prependCountryCodeToPhoneNumber } from "services/UtilityService";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import ConnectCallButton from "components/CallCenterOption/connectCallButton";
import { isLoggedInUserAWSConnectAgent } from "services/AWUtilityService";
import { getLoggedInUser } from "services/AuthService";

const PrescriberDetails = (props) => {
  const [prescriber, setPrescriber] = useState(props.prescriber);
  const { isDisabled, wfItemId, globalSwitchId } = props;
  const updatePrescriberData = (updatedPrescirber) => {
    setPrescriber(updatedPrescirber);
  };
  const isAWSConnectAgentEnable = isLoggedInUserAWSConnectAgent();
  return (
    <>
      <Row>
        <Col md={8}>
          <div data-testid='text-prescriber-name'>
            {prescriber.prescriberName} &nbsp;
            { prescriber.optedOut ?
              <Badge bg="warning" pill data-testid='badge-prescriber-opt-out'>
                Opted Out
              </Badge>
              :
              <></>
            } &nbsp;
            { prescriber.gatekeeper ?
              <Badge bg="warning" pill data-testid='badge-prescriber-gatekeeper'>
                Gatekeeper
              </Badge>
              :
              <></>
            }
          </div>
          <div style={{ fontSize: "0.8em" }} data-testid='text-prescriber-practice'>
            {prescriber.prescriberPractice}{" "}
          </div>
        </Col>
        <Col md={4}>
          <div className="rm-tiny-top-label">Suffix</div>
          <div data-testid='text-prescriber-credentials'>
            {prescriber.prescriberNameCredentialText
              ? prescriber.prescriberNameCredentialText
              : "N/A"}
          </div>
        </Col>
      </Row>
      {!isDisabled ? (
        <UpdatePrescriberInfo
          prescriber={prescriber}
          isDisabled={isDisabled}
          onSubmission={updatePrescriberData}
          updateData={props.updateData}
          wfItemId={props.wfItemId}
          isFromAW={props.isFromAW}
        />
      ) : null}
      {!isDisabled ? (
        <SwitchPrescriberInfo
          prescriber={prescriber}
          wfItemId={wfItemId}
          isDisabled={isDisabled}
          onSubmission={updatePrescriberData}
        />
      ) : null}

      <Row>
        <Col md={8}>
        <div className="rm-tiny-top-label tiny-top-spacer">Phone
        {isAWSConnectAgentEnable && <ConnectCallButton body={{ agent: getLoggedInUser()?.email, phone: prependCountryCodeToPhoneNumber(prescriber?.prescriberPhone, 1), switchId:globalSwitchId,entityName:prescriber.prescriberName,entityType:'Prescriber' }} label='Prescriber'/>}
        </div> 
        <div data-testid='text-prescriber-phone'>{formatPhoneNumber(prescriber.prescriberPhone)}</div>
        </Col>
        <Col md={4}>
        <div className="rm-tiny-top-label tiny-top-spacer">Time Zone</div>
        <div data-testid='text-prescriber-timezone'>{prescriber.timeZone ? prescriber.timeZone : "N/A"}</div>
        </Col>
      </Row>
      <div className="rm-tiny-top-label tiny-top-spacer">Fax</div>
      <div data-testid='text-prescriber-fax'>{formatPhoneNumber(prescriber.prescriberFax)}</div>
      <div className="rm-tiny-top-label tiny-top-spacer">Npi</div>
      <div data-testid='text-prescriber-npi'>{prescriber.prescriberNpi}</div>
      <div className="rm-tiny-top-label tiny-top-spacer">Address</div>
      <div data-testid='text-prescriber-address'>{prescriber.prescriberAddress ? prescriber.prescriberAddress : "N/A"}</div>
    </>
  );
};

export default withRouter(PrescriberDetails);
