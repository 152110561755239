import config from "config";
import { RSAA } from "redux-api-middleware";
import {
  AUTH_TOKEN_HEADER_KEY,
  getAuthorizationToken,
} from "../../../services/AuthService";
import { RESET_PAGE_TO_ZERO, RESET_NOTIFICATION_STATUS,RESET_ERROR_FIELD} from "./PawDsgs.slice"
import {DEFAULT_FILTER} from "./PawDsgs.contants"
export const getPawDsgListRequestAction = "GET_PAW_DSG_LIST_REQUEST";
export const getPawDsgListSuccessAction = "GET_PAW_DSG_LIST_SUCCESS";
export const getPawDsgListFailureAction = "GET_PAW_DSG_LIST_FAILURE";

export const changePawDSGOwnerRequestAction = "CHANGE_PAW_DSG_OWNER_REQUEST";
export const changePawDSGOwnerSuccessAction = "CHANGE_PAW_DSG_OWNER_SUCCESS";
export const changePawDSGOwnerFailureAction = "CHANGE_PAW_DSG_OWNER_FAILURE";


export const resetList = () => (dispatch) => {
  dispatch(RESET_PAGE_TO_ZERO());
};
export const resetNotificationStatus = () => (dispatch) => {
  dispatch(RESET_NOTIFICATION_STATUS());
};

export const resetListError = () => (dispatch) =>{
  dispatch(RESET_ERROR_FIELD());
}

export const getPAWDSGList =
  (pageSize, page, sorted, filter) =>
  (dispatch, getState) => {
    let filtered = filter;
    const ownerNameIndex = filtered.findIndex(
      (x) =>
        x.id == "ownerName" && !Array.isArray(x.value) && x.value.includes(",")
    );
    if (ownerNameIndex > -1) {
      filtered[ownerNameIndex].value =
        filtered[ownerNameIndex].value.split(",");
    }
    const rsaaObject = {
      [RSAA]: {
        endpoint: `${config.apiRoot}/paw-group/dsg/list`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
        },
        body: JSON.stringify({ pageSize, page, sorted, filtered }),
        types: [
          getPawDsgListRequestAction,
          {
            type: getPawDsgListSuccessAction,
            payload: async (action, state, res) => {
              const data = await res.json();
              return {
                pageSize: pageSize,
                sorted: sorted,
                filtered: filter,
                ...data,
              };
            },
          },
          getPawDsgListFailureAction,
        ],
      },
    };

    return dispatch(rsaaObject);
  };

  export const changePawDSGOwner =(dsgUuid,userInfoUuid,isUnassignedDrugGroups,isOpenDrugGroups)=>(dispatch, getState) => {
    const rsaaObject = {
        [RSAA]: {
          endpoint: `${config.apiRoot}/paw-group/dsg/${dsgUuid}/change-owner`,
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken(),
          },
          body: JSON.stringify({ userInfoUuid,isUnassignedDrugGroups,isOpenDrugGroups }),
          types: [
            changePawDSGOwnerRequestAction,
            {
              type: changePawDSGOwnerSuccessAction,
              payload: async (action, state, res) => {
                await res
                return;
              },
            },
            changePawDSGOwnerFailureAction,
          ],
        },
      };
  
      return dispatch(rsaaObject);

  }