import DismissableModal from "components/DismissableModal";
import { useRef, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import { FormSelect } from "react-bootstrap";
import { changePawDSGOwner } from "./PawDsgs.actions";
const styles = {
  buttonStyle: { margin: 0, padding: "3px 10px" },
  button: { marginRight: 10 },
};

const PawDsgsChangeOwner = ({ dsgUuid, dsgName,userList }) => {
  const [
    isUnassignedDrugGroupCheckboxChecked,
    setIsUnassignedDrugGroupCheckboxChecked,
  ] = useState(false); // Initialize state
  const [isOpenDrugGroupCheckboxChecked, setisOpenDrugGroupCheckboxChecked] =
    useState(false); // Initialize state
  const [warning, setWarning] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState("");
  const modalRef = useRef("changePawDsgOwner");
  const dispatch = useDispatch();
  const open = () => {
    modalRef.current.handleShow();
  };
  const modalOpener = (
    <Button variant="primary" style={styles.buttonStyle} onClick={open}>
      Change Owner
    </Button>
  );
  const changePawDSGOwnerButtonClick = () => {
    dispatch(
      changePawDSGOwner(
        dsgUuid,
        selectedUserId,
        isUnassignedDrugGroupCheckboxChecked,
        isOpenDrugGroupCheckboxChecked
      )
    );
    close();
  };
  const close = () => {
    if (modalRef?.current) {
      modalRef.current.handleClose();
    }
  };
  
  const customFooterContent = (
    <div>
      <Button
        variant="outline-dark"
        style={styles.button}
        // disabled={statusloading}
        onClick={close}
      >
        Cancel
      </Button>
      <Button
        className="left-spacer"
        variant={"success"}
        disabled={warning}
        onClick={() => {
            changePawDSGOwnerButtonClick();
        }}
      >
        {"Change Owner"}
      </Button>
    </div>
  );
  const onUserChange = (e) => {
    setSelectedUserId(e.target.value);
    if (
      e.target.value != "" &&
      (isUnassignedDrugGroupCheckboxChecked || isOpenDrugGroupCheckboxChecked)
    ) {
      setWarning(false);
    } else {
      setWarning(true);
    }
  };

  return (
    <DismissableModal
      ref={modalRef}
      key={dsgUuid}
      openModalButton={modalOpener}
      title={`Change Owner`}
      customFooterContent={customFooterContent}
      //   disabled={statusloading}
    >
      <p>
        Once you change owner for this DSG, all the active drug groups will be
        assigned to the below selected paw user
      </p>
      <Form.Check
        type="checkbox"
        checked={isUnassignedDrugGroupCheckboxChecked}
        label={"Unassigned DrugGroups"}
        onChange={(e) => {
          setIsUnassignedDrugGroupCheckboxChecked(e.target.checked);
          if (
            selectedUserId != "" &&
            (e.target.checked || isOpenDrugGroupCheckboxChecked)
          ) {
            setWarning(false);
          } else {
            setWarning(true);
          }
        }}
      />
      <Form.Check
        type="checkbox"
        checked={isOpenDrugGroupCheckboxChecked}
        label={"All Open DrugGroups"}
        onChange={(e) => {
          setisOpenDrugGroupCheckboxChecked(e.target.checked);
          if (
            selectedUserId != "" &&
            (isUnassignedDrugGroupCheckboxChecked || e.target.checked)
          ) {
            setWarning(false);
          } else {
            setWarning(true);
          }
        }}
      />
      {userList === [] ? (
        failure ? (
          <p className="text-danger"> Failed to load Reviewers</p>
        ) : (
          <p className="text-info"> Loading Reviewers ...</p>
        )
      ) : (
        <Form.Group>
          <Form.Label>Select Reviewer</Form.Label>
          <FormSelect
            id="switchdetails-select-sfr-reviewer"
            value={selectedUserId}
            onChange={onUserChange}
          >
            <option value="">Click to select a Reviewer</option>
            {userList.map((u, k) => (
              <option value={u.id} key={k} id={`switchdetails-user-id-${u.id}`}>
                {u.name}
              </option>
            ))}
          </FormSelect>
          {warning ? (
            <span className="text-danger smaller-feedback">
              Selecting a Reviewer is mandatory!
            </span>
          ) : (
            <span className="text-success smaller-feedback">Looks good!</span>
          )}
        </Form.Group>
      )}
    </DismissableModal>
  );
};
export default PawDsgsChangeOwner;
