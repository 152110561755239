import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ServerSideReactTable from "commons/table/ServerSideReactTable";
import { FilterTypes, ColumnTypes } from "commons/table/Constants";
import { useDispatch, useSelector } from "react-redux";
import { getPAWDSGList, resetList, resetNotificationStatus,resetListError} from "../PawDsgs/PawDsgs.actions";
import { hasRoleIn } from "rbac/RbacHelper";
import ROLES from "rbac/Roles.json";
import { Link, useParams } from "react-router-dom";
import ResetListState from "components/ResetListState";
import { DEFAULT_FILTER, DEFAULT_SORT } from "./PawDsgs.contants";
import PawDsgsChangeOwner from "./PawDsgsChangeOwner";
import { sendAuthenticatedAsyncRequest } from "../../../services/AsyncRequestService";
import { showNotification } from "services/NotificationService";


const PawDsgList = ({ history }) => {
  const dispatch = useDispatch();
  let isPAWAdmin = hasRoleIn([ROLES.PAW_ADMIN]);
  const pawDsgsState = useSelector((state) => state.pawDsgs);
  const pawDsgsLoading = pawDsgsState.isLoading;
  const totalRecords = pawDsgsState.totalElements;
  const pawDsgsList = useSelector((state) => state.pawDsgs.data);
  const changeOwnerStatus = useSelector((state) => state.pawDsgs.changeOwnerStatus);
  const [userList, setUserList] = React.useState([]);
  const hasError = useSelector((state) => state.pawDsgs.hasError);

  React.useEffect(() => {
    if (changeOwnerStatus == "Failed") {
      showNotification({
        title: "Failed to change owner of DSG!",
        message: "Something went wrong, Please try again",
        position: "tr",
        type: "error",
      });
    } else if (changeOwnerStatus == "Successful") {
      showNotification({
        title: "Successfully changed owner of DSG",
        position: "tr",
        type: "success",
      });
    }
    if(changeOwnerStatus!=""){
      dispatch(
        getPAWDSGList(
          pawDsgsState.pageSize,
          pawDsgsState.page,
          pawDsgsState.sorted,
          pawDsgsState.filtered
        )
      );
      dispatch(resetNotificationStatus())
    }
  }, [changeOwnerStatus]);


  React.useEffect(() => {
    if(hasError){
      showNotification({
        title: "Failed to change owner of DSG!",
        message: "Something went wrong, Please try again",
        position: "tr",
        type: "error",
      });
      dispatch(resetListError);
    }
  },[hasError]);



  
  const fetchUsers = () => {
    sendAuthenticatedAsyncRequest(
      `/paw-group/drug-groups/owners?excludeCurrentUser=false`,
      "GET",
      null,
      (r) => setUserList(r.data),
      (r) => setUserList([])
    );
  };
  React.useEffect(() => {
    dispatch(
      getPAWDSGList(
        pawDsgsState.pageSize,
        0,
        pawDsgsState.sorted,
        pawDsgsState.filtered
      )
    );
    fetchUsers();
  }, [dispatch]);
  
 
  const refreshList = () => {
    dispatch(resetList());
    onUpdateTable(DEFAULT_FILTER, DEFAULT_SORT, listState.pageSize, 0);
  };
  const changeOwner = (action, row) => {
    return (
      <PawDsgsChangeOwner
        dsgUuid={row.original?.dynamicSwitchGroupUuid}
        dsgName={row.original?.dynamicSwitchGroupName}
        isPAWAdmin={isPAWAdmin}
        userList={userList}
        refreshList={refreshList}
        key={`PawDsgsChangeOwner_${row.original?.dynamicSwitchGroupUuid}`}
      />
    );
  };
  const listState = {
    page: pawDsgsState.currentPage,
    pageSize: pawDsgsState.pageSize,
    sorted: pawDsgsState.sorted,
    filtered: pawDsgsState.filtered,
    totalPages: pawDsgsState.totalPages,
    resized: [],
    expanded: {},
  };
  const renderDsgLink = (value, row) => {
    return (
      <Link
        to={`/cat/dynamic-switch-groups/${row.original.dynamicSwitchGroupUuid}`}
        className={"column-left"}
      >
        {value}
      </Link>
    );
  };

  const columns = [
    {
      header: "Dynamic Switch Group",
      accessor: "dynamicSwitchGroupName",
      accessorKey: "dynamicSwitchGroupName",
      enableSorting: true,
      enableColumnFilter: true,
      columnType: ColumnTypes.Custom,
      filterType: FilterTypes.SearchableInput,
      customComponent: (value, row) => {
        return renderDsgLink(value, row);
      },
    },
    {
      header: "Codex Version",
      accessor: "codexVersion",
      accessorKey: "codexVersion",
      width: 200,
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Total Drug Groups",
      accessor: "totalDrugGroups",
      accessorKey: "totalDrugGroups",
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Open Drug Groups",
      accessor: "openDrugGroups",
      accessorKey: "openDrugGroups",
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Unassigned Drug Groups",
      accessor: "unassignedDrugGroups",
      accessorKey: "unassignedDrugGroups",
      enableSorting: true,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Open Drug Groups Owner",
      accessor: "owners",
      accessorKey: "owners",
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Text,
    },
    {
      header: "Action",
      accessor: "actionButton",
      accessorKey: "actionButton",
      enableSorting: false,
      enableColumnFilter: false,
      columnType: ColumnTypes.Custom,
      customComponent: changeOwner,
    },
  ];
  const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    dispatch(
      getPAWDSGList(pageSize, pageNo, sortBy ? sortBy : [], filterValues)
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <div className="codex-version-display-bar">
            <span style={{ float: "left" }}>
              {" "}
              Total PAW DSGs: <strong>{totalRecords}</strong>
            </span>
            <strong style={{ float: "center" }}> PAW DSG's</strong>
            <ResetListState
              onReset={refreshList}
              resetterDisabled={
                listState.filtered === DEFAULT_FILTER &&
                listState.sorted === DEFAULT_SORT
              }
            />
          </div>
        </Col>
      </Row>
      <ServerSideReactTable
        columnDefination={columns}
        overrideRowId={"drugGroupId"}
        data={pawDsgsState.data}
        currentPage={listState.page}
        sorted={listState.sorted}
        filters={listState.filtered}
        totalPages={listState.totalPages}
        totalRecords={totalRecords == "loading" ? 0 : totalRecords}
        loading={pawDsgsState.pawDsgsLoading}
        enableMultiRowSelection={false}
        defaultPageSize={pawDsgsState.pageSize}
        pageSizeOptions={[5, 10, 15, 20, 30, 50]}
        onUpdateTableData={onUpdateTable}
      />
    </Container>
  );
};
export default PawDsgList;
