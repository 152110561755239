import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Card } from "react-bootstrap";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import {
  Col,
  FormGroup,
  Row,
  FormLabel,
  Container,
  FormControl,
  Button,
  Form,
} from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import ReactBadge from "react-bootstrap/Badge";
import BulkActionList from "./BulkActionList/BulkActionList";
import { useDispatch, useSelector } from "react-redux";
import {
  scheduleBulkActionRequest,
  resetSuccess,
  cancelBulkActionRequest,
  resetSuccessCancel,
  updateBulkActionRequest,
  resetSuccessUpdation,
  resetSuccessChildCreation,
  createNewChildBulkActionRequest,
} from "./SchedularForm.actions";
import { showNotification } from "services/NotificationService";
import { useHistory } from "react-router-dom";
import MessageBanner from "components/MessageBanner";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import ScheduleBulkActionModal from "./ScheduleBulkActionModal";

const Tip = (props) => (
  <div style={{ textAlign: "right", color: "#000000", fontSize: "0.9em" }}>
    <em>{props.children}</em>
  </div>
);
const customStyles = {
  multiValue: (base) => ({
    background: "#007BBB",
    display: "inline-flex",
    color: "white",
    borderRadius: "11px",
    alignItems: "center",
    margin: "2px",
  }),
  multiValueLabel: (base) => ({
    fontSize: "13px",
    color: "white",
    padding: "4px 10px 4px 10px",
  }),
};
const BulkActionForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    actionType,
    filterSelectMap,
    filterSelectMapOriginal,
    setFilterSelectMap,
    setIsRerouting,
    descriptionFromProps,
    scheduledDate,
    clientNames,
    customerName,
    isFromProps,
    noOfSwitches,
    bulkActionUuid,
    noteFromProps,
    isEditable,
    isViewable,
    showCancel,
    isCompleted,
    showBioSimilarToggle,
  } = props;
  const [date, setDate] = useState(
    scheduledDate !== undefined ? new Date(scheduledDate) : new Date()
  );
  const [description, setDescription] = useState(descriptionFromProps ?? "");
  const [noOfSelectedSwitches, setNoOfSelectedSwitches] = useState(
    noOfSwitches ?? 0
  );
  const [fetchSwitchList, setFetchSwitchList] = useState(false);
  const [initialSetupDone, setInitialSetUpDone] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    clientNameAbbreviation: clientNames ?? [],
    customerNameAbbreviation: customerName ?? [],
  });
  const [note, setNote] = useState(noteFromProps ?? "");
  const [error, setError] = useState("");
  const errorForUpdation = useSelector((state) => state.schedularForm.error);
  const [clicked, setClicked] = useState(false);
  const [switchList, setSwitchList] = useState([]);
  const [selectedClientNameAbbreviation, setSelectedClientNameAbbreviation] =
    useState([]);

  const [
    selectedCustomerNameAbbreviation,
    setSelectedCustomerNameAbbreviation,
  ] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCreateNewChildModal, setCreateNewChildModal] = useState(false);
  const [bioSimilarSwitchesToggle, setBioSimilarSwitchesToggle] =
    useState(false);

  const handleShowUpdateModal = () => setShowUpdateModal(true);
  const handleCloseUpdateModal = () => setShowUpdateModal(false);

  const handleShowCreateNewChildModal = () => setCreateNewChildModal(true);
  const handleCloseCreateNewChildModal = () => setCreateNewChildModal(false);

  const handleShowCancelModal = () => setShowCancelModal(true);
  const handleCloseCancelModal = () => setShowCancelModal(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleConfirmSchedule = () => {
    scheduleBulkAction();
  };
  const submittingRequest = useSelector(
    (state) => state.schedularForm.submittingRequest
  );
  const isEventRequestCompleted = useSelector(
    (state) => state.schedularForm.eventRequestCompleted
  );

  const cancelEventRequestCompleted = useSelector(
    (state) => state.schedularForm.cancelEventRequestCompleted ?? false
  );

  const updateEventRequestCompleted = useSelector(
    (state) => state.schedularForm.updateEventRequestCompleted ?? false
  );
  const isSuccessfulUpdation = useSelector(
    (state) => state.schedularForm.isSuccessfulUpdation ?? false
  );

  const createNewChildEventRequestCompleted = useSelector(
    (state) => state.schedularForm.createNewChildEventRequestCompleted ?? false
  );
  const isSuccessfulChildCreation = useSelector(
    (state) => state.schedularForm.isSuccessfulChildCreation ?? false
  );

  const addClientsToCustomers = (customerListArray, clientListArray) => {
    let extractedSelectedClients = clientListArray;

    if (clientListArray.length === 0) {
      const appliedCustomerFilters = customerListArray.map(
        (customer) => customer.value
      );
      const extractedSelectedCustomers = filterSelectMap[
        "customerClientMapping"
      ].filter((e) => appliedCustomerFilters.includes(e.value));
      const extractedClients = extractedSelectedCustomers.reduce(
        (result, entry) => {
          return result.concat(entry.label);
        },
        []
      );

      extractedSelectedClients =
        filterSelectMapOriginal.clientNameAbbreviation.filter((item) =>
          new Set(extractedClients).has(item.label)
        );
    }
    return extractedSelectedClients;
  };

  useEffect(() => {
    if (!isFromProps && actionType.value.length > 0) {
      setDescription("");
      setDate(new Date());
      setFilterSelectMap(filterSelectMapOriginal);
      setSelectedFilters({
        clientNameAbbreviation: [],
        customerNameAbbreviation: [],
      });
      setSwitchList([]);
      setNoOfSelectedSwitches(0);
      setSelectedClientNameAbbreviation([]);
      setFetchSwitchList(false);
    }
  }, [isFromProps, actionType]);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const setSelectedSwitches = (selected) => {
    setSwitchList(selected);
    setNoOfSelectedSwitches(selected.length);
  };

  const isSuccess = useSelector((state) => state.schedularForm.isSuccess);

  const isSuccessfullCancel = useSelector(
    (state) => state.schedularForm.isSuccessfullCancel ?? false
  );

  const validateForm = (scheduledDate, switchList, description, note) => {
    var currentDate = new Date();
    if (scheduledDate < currentDate)
      return "Date and Time should be greater than current Time";
    if (switchList.length == 0) return "Switch list can not be empty";
    if (description && description.length > 100)
      return "Description can not exceed 100 characters in length";
    if (description.length === 0)
      return "Description is mandatory! Please do not forget to enter it while scheduling bulk action.";
    if (note.length === 0)
      return "The Notes field cannot be left empty. Please fill it before scheduling the bulk action.";
    return null;
  };

  useEffect(() => {
    if (isEventRequestCompleted && isSuccess) {
      showNotification({
        title: "Bulk Action Created",
        message: "Bulk Action Scheduled Successfully",
        position: "bl",
        type: "success",
      });
      setIsRerouting(true);
      dispatch(resetSuccess());
      setTimeout(() => {
        setIsRerouting(false);
        history.push("/scheduler/bulk-action/list");
      }, 1000);
    }
  }, [isSuccess, isEventRequestCompleted]);

  useEffect(() => {
    if (cancelEventRequestCompleted && isSuccessfullCancel) {
      showNotification({
        title: "Bulk Action Cancelled",
        message: "Bulk Action Cancelled Successfully",
        position: "bl",
        type: "success",
      });
      setIsRerouting(true);
      dispatch(resetSuccessCancel());
      setTimeout(() => {
        setIsRerouting(false);
        history.push("/scheduler/bulk-action/list");
      }, 1000);
    }
  }, [cancelEventRequestCompleted, isSuccessfullCancel]);

  useEffect(() => {
    if (isSuccessfulUpdation && updateEventRequestCompleted) {
      showNotification({
        title: "Bulk Action Updated",
        message: "Bulk Action Updated Successfully",
        position: "bl",
        type: "success",
      });
      setIsRerouting(true);
      dispatch(resetSuccessUpdation());
      setTimeout(() => {
        setIsRerouting(false);
        history.push("/scheduler/bulk-action/list");
      }, 1000);
    }
  }, [isSuccessfulUpdation, updateEventRequestCompleted]);

  useEffect(() => {
    if (isSuccessfulChildCreation && createNewChildEventRequestCompleted) {
      showNotification({
        title: "Child Bulk Action Created",
        message: "Child Bulk Action Created Successfully",
        position: "bl",
        type: "success",
      });
      setIsRerouting(true);
      dispatch(resetSuccessChildCreation());
      setTimeout(() => {
        setIsRerouting(false);
        history.push("/scheduler/bulk-action/list");
      }, 1000);
    }
  }, [isSuccessfulChildCreation, createNewChildEventRequestCompleted]);

  const scheduleBulkAction = () => {
    let clientList = [];
    clientList = selectedFilters.clientNameAbbreviation.reduce(
      (result, entry) => {
        return result.concat(entry.value);
      },
      []
    );

    let customerList = [];
    customerList = selectedFilters.customerNameAbbreviation.reduce(
      (result, entry) => {
        return result.concat(entry.value);
      },
      []
    );
    var validationMessage = validateForm(date, switchList, description, note);
    if (validationMessage === null) {
      let requestDto = {};
      if (
        actionType &&
        (actionType.value === "Cycle" || actionType.value === "Batch")
      ) {
        requestDto = {
          scheduledDate: date,
          description: description,
          wfItemUuids: switchList,
          clientList: clientList,
          customerList: customerList,
          actionType: actionType.value,
          note: note,
          isBioSimilarBulkAction: bioSimilarSwitchesToggle ? true : false,
        };
      } else {
        requestDto = {
          scheduledDate: date,
          description: description,
          wfItemUuids: switchList,
          clientList: clientList,
          customerList: customerList,
          actionType: actionType.value,
          note: note,
          isBioSimilarBulkAction: bioSimilarSwitchesToggle ? true : false,
        };
      }
      dispatch(scheduleBulkActionRequest(requestDto));
    } else {
      setError(validationMessage);
    }
  };
  const handleNotesChange = (event) => {
    setNote(event.target.value);
  };
  useEffect(() => {
    if (
      !initialSetupDone &&
      Object.keys(filterSelectMap).length > 0 &&
      isFromProps
    ) {
      const initialClientSelection = filterSelectMap[
        "clientNameAbbreviation"
      ].filter((option) => clientNames.includes(option.label));
      const initialCustomerSelection = filterSelectMap[
        "customerNameAbbreviation"
      ].filter((option) => customerName.includes(option.label));
      setSelectedFilters({
        clientNameAbbreviation:
          initialClientSelection.length ===
          filterSelectMap["clientNameAbbreviation"][0].value.length
            ? [filterSelectMap["clientNameAbbreviation"][0]]
            : initialClientSelection,
        customerNameAbbreviation:
          initialCustomerSelection.length ===
          filterSelectMap["customerNameAbbreviation"][0].value.length
            ? [filterSelectMap["customerNameAbbreviation"][0]]
            : initialCustomerSelection,
      });

      setInitialSetUpDone(true);
    }
  }, [filterSelectMap, isFromProps, initialSetupDone]);

  useEffect(() => {
    if (
      initialSetupDone &&
      isFromProps &&
      selectedFilters.clientNameAbbreviation &&
      selectedFilters.customerNameAbbreviation
    ) {
      searchSwitches();
    }
  }, [initialSetupDone, isFromProps, selectedFilters]);

  const handleCustomerChange = (option) => {
    if (option != null) {
      let clientOptions;
      const appliedCustomerFilters = option.label;
      setSwitchList([]);
      setNoOfSelectedSwitches(0);
      setSelectedClientNameAbbreviation([]);
      setFetchSwitchList(false);
      const extractedSelectedCustomers = filterSelectMap[
        "customerClientMapping"
      ].filter((e) => e.value === appliedCustomerFilters);

      const extractedCustomers = extractedSelectedCustomers.reduce(
        (result, entry) => {
          return result.concat(entry.label);
        },
        []
      );

      if (appliedCustomerFilters === "ALL") {
        clientOptions = [filterSelectMapOriginal["clientNameAbbreviation"][0]];
      } else {
        clientOptions = filterSelectMapOriginal[
          "clientNameAbbreviation"
        ].filter((e) => extractedCustomers.includes(e.label));
      }

      setFilterSelectMap((prevState) => ({
        ...prevState,
        clientNameAbbreviation: clientOptions,
      }));

      const updatedCustomerList = option !== null ? [option] : [];
      setSelectedFilters({
        clientNameAbbreviation: [],
        customerNameAbbreviation: [...updatedCustomerList],
      });
    }
  };

  const searchSwitches = () => {
    let reducedClientListArray = addClientsToCustomers(
      selectedFilters.customerNameAbbreviation,
      selectedFilters.clientNameAbbreviation
    );

    reducedClientListArray = reducedClientListArray.reduce((result, entry) => {
      if (entry.label === "ALL") {
        return result.concat(
          filterSelectMapOriginal["clientNameAbbreviation"]
            .filter((e) => e.label != "ALL")
            .map((e) => e.label)
        );
      }
      return result.concat(entry.label);
    }, []);
    const reducedCustomerArray =
      selectedFilters.customerNameAbbreviation.reduce((result, entry) => {
        if (entry.label === "ALL") {
          return result.concat(
            filterSelectMapOriginal["customerNameAbbreviation"]
              .filter((e) => e.label != "ALL")
              .map((e) => e.label)
          );
        }
        return result.concat(entry.label);
      }, []);

    setSelectedClientNameAbbreviation(reducedClientListArray);
    setSelectedCustomerNameAbbreviation(reducedCustomerArray);
    setFetchSwitchList(true);
  };

  const onDateChange = (date) => {
    setDate(date);
  };

  const updateBulkAction = () => {
    let clientList = [];
    clientList = selectedFilters.clientNameAbbreviation.reduce(
      (result, entry) => {
        return result.concat(entry.value);
      },
      []
    );

    let customerList = [];
    customerList = selectedFilters.customerNameAbbreviation.reduce(
      (result, entry) => {
        return result.concat(entry.value);
      },
      []
    );
    var validationMessage = validateForm(date, switchList, description, note);
    if (validationMessage === null) {
      let requestDto = {};
      requestDto = {
        scheduledDate: date,
        description: description,
        wfItemUuids: switchList,
        clientList: clientList,
        customerList: customerList,
        actionType: actionType.value,
        note: note,
      };
      dispatch(updateBulkActionRequest(requestDto, bulkActionUuid));
    } else {
      setError(validationMessage);
    }
  };

  const createNewChildBulkAction = () => {
    let clientList = [];
    clientList = selectedFilters.clientNameAbbreviation.reduce(
      (result, entry) => {
        return result.concat(entry.value);
      },
      []
    );

    let customerList = [];
    customerList = selectedFilters.customerNameAbbreviation.reduce(
      (result, entry) => {
        return result.concat(entry.value);
      },
      []
    );
    var validationMessage = validateForm(date, switchList, description, note);
    if (validationMessage === null) {
      let requestDto = {};
      requestDto = {
        scheduledDate: date,
        description: description,
        wfItemUuids: switchList,
        clientList: clientList,
        customerList: customerList,
        actionType: actionType.value,
        note: note,
      };
      dispatch(createNewChildBulkActionRequest(requestDto, bulkActionUuid));
    } else {
      setError(validationMessage);
    }
  };

  const cancelBulkAction = () => {
    if (bulkActionUuid) {
      dispatch(cancelBulkActionRequest(bulkActionUuid));
    }
  };

  useEffect(() => {
    if (
      selectedFilters.customerNameAbbreviation &&
      selectedFilters.customerNameAbbreviation[0]?.label === "ALL"
    ) {
      setFilterSelectMap((prevState) => ({
        ...prevState,
        clientNameAbbreviation: [prevState.clientNameAbbreviation[0]], // Only keep the 'ALL' option for clients
      }));
    }
  }, [selectedFilters.customerNameAbbreviation]);

  const handleClientChange = (option) => {
    const updatedClientList = option !== null ? option : [];
    setSwitchList([]);
    setNoOfSelectedSwitches(0);
    setSelectedClientNameAbbreviation([]);
    setSelectedCustomerNameAbbreviation([]);
    setFetchSwitchList(false);
    setSelectedFilters((prevState) => ({
      ...prevState,
      clientNameAbbreviation: updatedClientList,
    }));
  };
  return (
    <Container fluid className="top-spacer">
      <Col md={{ span: 13 }}>
        {errorForUpdation && errorForUpdation?.length > 0 && (
          <MessageBanner
            message={
              "You cannot update the bulk action as the time limit is reached for editing"
            }
            isDisplayMessage={errorForUpdation.length > 0}
            icon={faExclamationCircle}
          />
        )}
        <Card>
          <Card.Header>
            <Card.Title className="text-center">
              <h4>Scheduler</h4>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {error ? <Alert variant="danger">{error}</Alert> : null}
            {submittingRequest ? (
              <Alert variant="primary">{submittingRequest}</Alert>
            ) : null}
            <Row>
              <Col md={12}>
                <Tip>Enter Date & Time according to your Time Zone</Tip>
              </Col>
              <Col md={12}>
                <FormGroup as={Row}>
                  <Col md={3}>
                    <FormLabel>Date & Time</FormLabel>
                  </Col>
                  <Col md={9}>
                    <DateTimePicker
                      onChange={onDateChange}
                      value={date}
                      disabled={isViewable}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup className="tiny-top-spacer" as={Row}>
                  <Col md={3}>
                    <FormLabel>Description</FormLabel>
                  </Col>
                  <Col md={9}>
                    <FormControl
                      type="text"
                      name="description"
                      value={description}
                      placeholder="Enter Description (Mandatory)"
                      onChange={handleDescriptionChange}
                      disabled={isViewable}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup className="tiny-top-spacer" as={Row}>
                  <Col md={3}>
                    <FormLabel>Customer</FormLabel>
                  </Col>
                  <Col md={9}>
                    <Select
                      options={
                        Object.keys(filterSelectMap).includes(
                          "customerNameAbbreviation"
                        )
                          ? filterSelectMap["customerNameAbbreviation"]
                          : []
                      }
                      value={selectedFilters.customerNameAbbreviation}
                      onChange={(event) => handleCustomerChange(event)}
                      placeholder={"Enter Customer (Optional)"}
                      closeMenuOnSelect={false}
                      styles={customStyles}
                      isDisabled={isEditable || isViewable}
                    ></Select>
                  </Col>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup className="tiny-top-spacer" as={Row}>
                  <Col md={3}>
                    <FormLabel>Client</FormLabel>
                  </Col>
                  <Col md={9}>
                    <Select
                      options={
                        Object.keys(filterSelectMap).includes(
                          "clientNameAbbreviation"
                        )
                          ? filterSelectMap["clientNameAbbreviation"]
                          : []
                      }
                      value={selectedFilters.clientNameAbbreviation}
                      onChange={(event) => handleClientChange(event)}
                      isMulti
                      placeholder={"Enter Client (Optional)"}
                      closeMenuOnSelect={false}
                      styles={customStyles}
                      isDisabled={isEditable || isViewable}
                    ></Select>
                  </Col>
                </FormGroup>
              </Col>
              <Col md={12}>
                <Form.Group className="tiny-top-spacer" as={Row}>
                  <Form.Label column md={3}>
                    Notes
                  </Form.Label>
                  <Col md={9}>
                    <Form.Control
                      as="textarea"
                      rows={1}
                      placeholder="Notes (Required)"
                      onChange={handleNotesChange}
                      value={note}
                      name="bulkActionNotes"
                      disabled={isViewable}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <FormGroup className="tiny-top-spacer" as={Row}>
                <Col md={3}>
                  <FormLabel>No. of Selected Switches </FormLabel>
                </Col>
                <Col md={9}>
                  <ReactBadge
                    id="no-of-selected-switches"
                    variant="success"
                    pill
                    style={{ margin: "-1px 5px", fontSize: "100%" }}
                  >
                    {noOfSelectedSwitches ? noOfSelectedSwitches : 0}
                  </ReactBadge>
                </Col>
              </FormGroup>
              <Col
                md={{ span: 9 }}
                className="d-grid gap-2 mt-4"
                style={{ marginLeft: "auto" }}
              >
                <Row className="justify-content-end">
                  <Col md={6}>
                    {!isViewable && (
                      <>
                        {isEditable && (
                          <Row>
                            <>
                              {!isCompleted ? (
                                <Col xs={showCancel ? 6 : 12}>
                                  <Button
                                    className="btn-block btn-text-white"
                                    variant="primary"
                                    onClick={handleShowUpdateModal}
                                    disabled={switchList.length === 0}
                                  >
                                    Update Bulk Action
                                  </Button>
                                  <ScheduleBulkActionModal
                                    show={showUpdateModal}
                                    handleClose={handleCloseUpdateModal}
                                    handleConfirm={updateBulkAction}
                                    message={
                                      "Are you sure you want to update this bulk action ?"
                                    }
                                    title={"Update Bulk Action"}
                                    cancelDanger={false}
                                  />
                                </Col>
                              ) : (
                                <Col xs={showCancel ? 6 : 12}>
                                  <Button
                                    className="btn-block btn-text-white"
                                    variant="primary"
                                    onClick={handleShowCreateNewChildModal}
                                    disabled={switchList.length === 0}
                                  >
                                    Create A New Child Bulk Action
                                  </Button>
                                  <ScheduleBulkActionModal
                                    show={showCreateNewChildModal}
                                    handleClose={handleCloseCreateNewChildModal}
                                    handleConfirm={createNewChildBulkAction}
                                    message={
                                      "Are you sure you want to create a child from this bulk action ?"
                                    }
                                    title={"Create A New Child Bulk Action"}
                                    cancelDanger={false}
                                  />
                                </Col>
                              )}
                            </>
                            {showCancel && (
                              <>
                                <Col xs={6}>
                                  <Button
                                    className="btn-block btn-text-white"
                                    variant="danger"
                                    onClick={handleShowCancelModal}
                                  >
                                    Cancel Bulk Action
                                  </Button>
                                </Col>
                                <ScheduleBulkActionModal
                                  show={showCancelModal}
                                  handleClose={handleCloseCancelModal}
                                  handleConfirm={cancelBulkAction}
                                  message={
                                    "Are you sure you want to cancel this bulk action ?"
                                  }
                                  title={"Cancel Bulk Action"}
                                  cancelDanger={true}
                                />
                              </>
                            )}
                          </Row>
                        )}
                        <div>
                          {fetchSwitchList && !isEditable && (
                            <>
                              <Button
                                className="btn-block btn-text-white"
                                variant="success"
                                onClick={handleShowModal}
                                disabled={switchList.length === 0}
                              >
                                Schedule Bulk Action
                              </Button>
                              <ScheduleBulkActionModal
                                show={showModal}
                                handleClose={handleCloseModal}
                                handleConfirm={handleConfirmSchedule}
                                message={
                                  "Are you sure you want to schedule this bulk action ?"
                                }
                                title={"Schedule Bulk Action"}
                                cancelDanger={false}
                              />
                            </>
                          )}
                        </div>
                        {!isEditable && !fetchSwitchList && (
                          <Button
                            onClick={searchSwitches}
                            className="btn-block btn-text-white"
                            disabled={
                              actionType.value === "Client Pause" &&
                              selectedFilters.clientNameAbbreviation.length ===
                                0 &&
                              selectedFilters.customerNameAbbreviation
                                .length === 0
                            }
                          >
                            Search
                          </Button>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {fetchSwitchList === false &&
        (selectedClientNameAbbreviation.length === 0 ||
          selectedCustomerNameAbbreviation.length === 0) ? null : (
          <BulkActionList
            selectedClientNameAbbreviation={selectedClientNameAbbreviation}
            selected={switchList}
            setSelectedSwitches={setSelectedSwitches}
            moveToTheListView={clicked}
            actionType={actionType}
            bulkActionUuid={bulkActionUuid}
            isViewable={isViewable}
            isEditable={isEditable}
            selectedCustomerNameAbbreivation={selectedCustomerNameAbbreviation}
            bioSimilarSwitchesToggle={bioSimilarSwitchesToggle}
            setBioSimilarSwitchesToggle={setBioSimilarSwitchesToggle}
            showBioSimilarToggle={showBioSimilarToggle}
            toggleDefaultFilters={searchSwitches}
            filters={filterSelectMap}
          />
        )}
      </Col>
    </Container>
  );
};

export default withRouter(BulkActionForm);
